export default {
  header: {
    login: "登录",
    history: "浏览记录",
    search: "搜索",
    account: "个人中心",
    viewAll: "全部",
    alertViewAll: "查看全部",
    tipsTitle: "为什么不试试提醒呢？",
    tipsText: "永远不要错过你喜欢的好东西。",
    newAlert: "查看所有<b> {number} </b>家店铺和价格",
    historyTitle: "最近浏览",
    historyTipsTitle: "没有看到任何产品吗？",
    historyTipsText: "超过300000种产品正在等待您。"
  },
  pre: {
    brandWomen: "女士推荐品牌",
    brandMen: "男士推荐品牌",
    brandKids: "儿童推荐品牌",
    brandHome: "家居推荐品牌",
    categoryWomen: "女士时尚品类",
    categoryMen: "男士时尚品类",
    categoryKids: "儿童时尚品类",
    categoryHome: "时尚家居品类"
  },
  login: {
    tips1:
      "我们汇聚了全球领先的电子商务零售商。<br/> 策划风格和时尚的更多选择。",
    tips2: "终极一站式购物目的地，<br/> 各种尺寸和款式任您选择。",
    tips3: "用户友好，易于找到您喜欢的内容。",
    tips4: "比较以获得最佳交易。",
    tips5: "让您了解最新的时尚和风格。",
    tips6: "每天减价。",
    tips7: "直接向零售商付款并由零售商发货。",
    account: "电子邮件地址",
    newAccount: "新电子邮件地址",
    password: "密码",
    loginBtn: "登录",
    show: "显示",
    hide: "隐藏",
    registerBtn: "创建账户",
    resetEmail: "修改邮件地址",
    forgetPassword: "忘记密码？",
    retrievePassword: "重置密码",
    verificationCode: "验证码",
    with: "或使用以下登录方式",
    withRegister: "或使用以下注册方式",
    emailTips: "请输入有效电子邮件地址。",
    newEmailTips: "请输入有效电子邮件地址。",
    keyPasswordTips: "请填写密码。",
    keyCodeTips: "请填写验证码。",
    passwordTips: "密码必须在6位至30位之间",
    newPasswordTips: "新密码必须在6位至30位之间",
    passwordInvalidTips: "密码错误。",
    accountTips: "抱歉，我们没能找到您的账号，请创建",
    registerText:
      "通过创建帐户，您同意33boxes的条款和政策。<br/>了解我们收集数据的原因以及如何保护您的隐私。<br/>请阅读隐私政策。<br/>你可以随时选择退出。",
    registerSuccessTips: "感谢加入我们，请重新登录尽情享受购物的乐趣吧！",
    registeredPrompt: "邮箱地址已被注册。",
    newPassword: "新密码",
    resetPassword: "重置密码",
    retrievePasswordText:
      "请输入您的登录电子邮件，<br/>并将向您的邮箱发送重置密码的代码。",
    resetSuccessfulTips: "重置成功，请登录。",
    resetPasswordTips: "原始密码不能与新密码相同。",
    resetAccountTips: "原始账号不能与新账号相同。",
    logoutConfirm: "您确定要退出系统吗?",
    sendCode: "发送验证码",
    sendError: "发送失败，请重新尝试。",
    resetFailed: "重置失败，请重新尝试。",
    resetSuccess: "密码已发送到登录邮箱"
  },
  userCenter: {
    accountInfo: "帐户信息",
    searchAlertsWishlist: "我的搜索 / 提醒 / 意愿清单",
    preferences: "偏好",
    orderHistory: "订单历史",
    logout: "注销",
    search: "搜索",
    alerts: "提醒",
    wishlist: "意愿清单",
    deleteAll: "删除全部",
    cancel: "取消",
    confirm: "同意",
    save: "保存",
    deleteTips: "你确定要删除所有“我喜欢的”吗？",
    wishlistTitle: "别让他们走！",
    wishlistText: "容易收集，容易购买。",
    alertsDeleteTips: "是否确实要删除所有“我的提醒”？",
    alertsTitle: "为什么不试试警报呢？",
    alertsText: "永远不要错过你喜欢的好东西。",
    country: "国家 / 地区",
    language: "语言",
    currency: "货币",
    newsLetter: "时事通讯",
    promotionsNotifications: "促销和通知",
    yes: "是",
    no: "否",
    preferencesText:
      "您可以随时取消订阅。<br/>要了解更多信息，请阅读隐私政策。",
    saveTips: "保存成功",
    unsubscribed: "取消订阅",
    selectSize: "选择大小",
    preferPrice: "优惠价格",
    priceChange: "价格有变化吗",
    setAlert: "设置提醒",
    emailAndPassword: "邮箱及密码设置",
    change: "更换"
  },
  menu: {
    shopNow: "即刻前往",
    viewAll: "查看所有",
    featuredBrands: "特色品牌",
    brands: "品牌",
    category: "分类",
    price: "价格",
    size: "尺码",
    color: "颜色",
    newSale: "新款 / 折扣",
    newIn: "新款 / 折扣 / 古着",
    isNew: "新款",
    isSale: "折扣",
    isOld: "古着",
    sizeGuide: "尺码对照表",
    clothing: "服装",
    retrieval: "检索全部"
  },
  carousel: {
    numberContent: "超级大减价<br>看起来很帅",
    more: "更多",
    moreContent: "超级大减价<br/>让你看起来更漂亮",
    login: "登录",
    signUp: "注册",
    tipsTitle: "还没有成为会员？",
    tipsText1: "加入并建立自己的生活方式库。",
    tipsText2: "加入全球时尚专卖店，享受独家优惠。",
    searchTipsTitle: "非常抱歉",
    searchTipsText:
      "<font>{search}</font>目前不在售。<br/>不如试试其他同类产品？"
  },
  signup: {
    women: "女士",
    men: "男士",
    kids: "儿童",
    home: "家居",
    textTips: "您可以随时取消订阅。<br>要了解更多信息，请阅读隐私政策。",
    btn: "订阅我们的时事通讯",
    thanks: "感谢！",
    submitText: "你很快就会收到一封电子邮件。"
  },
  headline: {
    superSale: "超级折扣",
    discover: "发现风格",
    brandWeekly: "品牌周选",
    featured: "必备星选",
    registeredMember: "注册成为33boxes会员",
    more: "更多",
    recommend: "为您推荐",
    popularKeywords: "热搜关键词",
    popularBrands: "热搜品牌",
    featuredProducts: "必备单品"
  },
  footer: {
    newsletterSignup: "通讯注册",
    helpCentre: "帮助中心",
    contactUs: "联系我们",
    aboutUs: "关于我们",
    accessibility: "无障碍浏览",
    termsConditions: "条件与条款",
    privacyPolicy: "隐私政策",
    service: "服务",
    HelpCenter:
      "“33boxes - 三三合”是全新的国际时尚购物及资讯平台。顶级零售商云集，操作界面简单友好，货品及渠道信息全面、精准，无限量全新及折扣遍及服装、鞋履、配饰、珠宝和腕表、美妆、家居及经典古着，让网购畅快淋漓，令生活多姿多彩。",
    help1: "轻松畅游",
    help2:
      "“33boxes - 三三合”是全新的国际时尚购物及资讯平台。顶级零售商云集，操作界面简单友好，货品及渠道信息全面、精准，无限量全新及折扣遍及服装、鞋履、配饰、珠宝和腕表、美妆、家居及经典古着，让网购畅快淋漓，令生活多姿多彩。",
    help3: "快速搜索标签",
    help4:
      "注册后，在物品列表页面过滤器中点击“书签”按钮即可以建立“快速搜索标签”。进入“我的账户”点击“我的搜索标签”中选线可直接进入过滤后的页面购物，减少筛选流程。",
    help5: "心愿单",
    help6:
      "注册后，点击单品上的“红心”按钮对其进行收藏。进入“我的账户”点击“我的心愿单”在统一前往各零售商结算，更快更便捷。",
    help7: "提示",
    help8:
      "注册后，点击单品上的“铃铛”按钮可对心仪商品设置提示，价格变动、库存量实时掌握。",
    help9: "关于购买和订单",
    help10:
      "33boxes作为时尚及生活类信息平台，我们在现阶段提供全面的零售商货品链接、折扣优惠代码、时尚生活资讯等，并不能直接购买。所以当您通过我们前往零售商网站购物，会收到零售商的订单确认。",
    help11: "产品信息有误",
    help12:
      "我们致力于为您打造轻松愉悦的购物体验。虽然产品信息有误的情况非常罕见，但是对此种情况造成的不便我们深表歉意。如果您遇到类似问题，烦请联系我们的团队。<font>customerservice@33boxes.com</font>",
    help13: "没有您熟悉的尺码",
    help14:
      "33boxes所提供的尺码来自于多家电商及品牌。由于国家、地区、品牌的不同，货品的默认尺码也不尽相同。所以在选购商品时请参阅“尺码对照表”。",
    help15: "如何更精准的查找",
    help16: "可以通过“过滤器”对商品列表进行筛选。",
    help17: "货币、配送及关税",
    help18:
      "33boxes的商品来自全球多家零售商，因此货币、配送及关税问题会由各家电商及您所在国家地区的政策所决定。更多细节请联系目标电商和相关管理部门进行确认。",
    help19: "退换货品",
    help20:
      "如果您对所消费的物品不满需要进行调换或退款，请联系出售货品的零售商或品牌方。",
    contactUs1: "我们的团队期待听到您的任何声音。",
    contactUs2:
      "如果您有关于购物的问题，可以访问帮助中心，或发送电子邮件至：<font>customerservice@33boxes.com</font>",
    contactUs3:
      "如果您是商店、品牌或设计师有合作意向或疑问，欢迎发送邮件至：<font>storeservice@33boxes.com</font>",
    contactUs4:
      "如果您是媒体有任何采访需求，欢迎发送邮件至：<font>media@33boxes.com</font>",
    contactUs5: "我们的注册办公地位于：xxxxx",
    aboutUs1: "33boxes （三三合）来自瑞士，是崭新的时尚生活资讯及购物平台。",
    aboutUs2:
      "我们精选全球头部及特色零售商、品牌，集中更多商品，提供更多、更优的选择；前沿、实用的资讯，传递更时尚、更优质理念；友好、纯净的平台，助力突破信息壁垒，更省时、更轻松地享受购物带来的乐趣。",
    accessibility1:
      "33boxes致力于为每个人打造完美的网络购物体验，所以我们乐意细心聆听您的建议并积极改进。若您在使用我们的产品时遇到任何使用方面的困难，或发现任何功能对“无障碍浏览”造成障碍，请将您的困扰详尽地描述并通过电子邮件的方式反馈给我们：<font>accessibility@33boxes.com</font> <br/><br/>非常感谢！",
    terms1: "欢迎来到33boxes(三三合)。",
    terms2:
      "33boxes是一家瑞士公司，注册地点位于xxxxxxxxxxxxxxx。33boxes(三三合)网站(以下简称“网站”、“33boxes”、“我们”、“我方”、“我们的”、“三三合”)是一个电子商务平台，为客人提供购买服装、鞋履、配饰、珠宝及腕表、美妆、家居产品和二手物品购买信息和渠道。(“客人”指使用或注册账户使用本33boxes的个人)",
    terms3:
      "您注册、登录33boxes或使用33boxes提供的服务，即表示您同意接受本政策。在您开始使用我们的产品和服务前，请您务必仔细阅读并理解本政策，确保您充分理解之后再开始使用。本政策中涉及的相关术语，我们尽量以简明扼要的方式进行表述，以便您更好地理解。",
    terms4:
      "如对本政策内容有任何疑问、意⻅或建议，您可通过本政策文末的联系方式与我们联系。",
    terms5: "注册与登录",
    terms6:
      "您使用“33boxes”提供的产品和服务，可以注册并登录经注册的“33boxes”账号。注册并非必要选项，但注册后方可使用拓展功能(如收藏、提示等)。",
    terms7:
      "在注册时，需要提供电子邮件地址、用户名和密码等信息，我们保留拒绝或删除任何用户名的权利。当您注册 “33boxes”账号时，您需要提供电子邮件地址、用户名和密码等信息，我们保留拒绝或删除任何用户名的权利。我们将通过邮件的方式来验证您的身份是否有效，验证无误后我们会为您创建账号，您也可以设置登录密码。",
    terms8:
      "您也可以使用第三方账号(如Facebook)登录并使用“什么值得买”，此时您需要授权我们获取您在第三方平台的信息(头像、昵称等公开信息以及您授权的其他信息)，但您仍需要提供您的邮箱用于“33boxes”账号的创建与绑定，账号创建及绑定后，您可以直接使用第三方账号登录并使用本产品和相关服务。我们可能从第三方获取您的上述信息的目的是为了记住您作为“33boxes”用户的登录身份。您可以自行对您享有的上述第三方账号与“33boxes”平台进行绑定和解绑操作。",
    terms9:
      " (如有Apple的登录方式的话)对于使用“通过Apple登录”功能的用户，请您知悉并了解，我们不会收集储存在 您设备当中的Apple ID及电子邮箱信息，并有权根据本政策要求您提供其他创建、登录账号的必要信息(邮箱及登录密码);对于“通过Apple登录”功能涉及的其他个人信息相关问题，请您查看Apple的隐私政策加以了解。",
    terms10:
      "当您注册33boxes，即表示您同意以电子邮件和推送的方式与我们保持沟通。",
    terms11: "用户年龄",
    terms12:
      "16岁以下未成年人不得使用本服务，父母或法定监护人不得代表其同意本条款及条件。如果我们发现16岁以下未成年人已经或试图注册并提供个人信息，我们有权利不经过该用户的同意直接删除相关信息。",
    terms13: "您需要向我们声明并保证: ",
    terms14:
      "您的年龄超过16岁(非公司);您已具备签订具有约束力的合同的法定年龄条件或取得父母的许可;您提交的注册信息准确、真实;法律允许您使用和访问服务，并由您承担全部相关责任;特别的，若您是已满十四周岁 且未满十八周岁的未成年人，在使用我们的产品和服务前，应事先取得您的父母或其他监护人的同意，并在监护人的监护、指导、允许和约束的条件下阅读并同意本政策使用本网站;如果您是超过16岁的未成年人的监护人，您作为监护人应当正确履行监护职责，保护未成年人的个人信息安全。若未成年人本人需要注册或使用我 们的产品和服务，您应正确引导并予以监护。在同意子女使用本网站并接受本条款与条件时，您将承担全部您子女使用本网站的连带法律责任。",
    terms15: "非商业用途",
    terms16:
      "本网站及内容仅供个人或非商业使用，非经本网站授权，不得修改、复制、公布、分发或以其他任何方式用于任何公开或商业目的，不得用于其他任何网站、平面媒体、影视广播、录音录像或数字化制品以及任何可能侵犯本网站著作权的范畴。我们保留对任何未经授权的使对本网站著作权及其他权利侵犯的诉讼权利。",
    terms17: "本政策如何更新及适用范围",
    terms18:
      "为给您提供更好的服务以及随着“33boxes”业务的发展，本政策也会随之更新。我们可以随时停止或更改本网站的内容或功能以及本政策，并无需征得您的同意。但未经您明确同意，我们不会削减您依据本政策所应享有的权利。我们会在“33boxes”平台⻚面上发布更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问“33boxes”平台以便及时了解最新的个人信息保护政策。",
    terms19:
      "对于重大变更，我们还会提供更为显著的通知方式向您发送变更通知，包括但不限于私信通知、电子邮件通知或在浏览⻚面做特别提示等方式，说明个人信息保护政策的具体变更内容。",
    terms20: "本政策所指的重大变更包括但不限于:",
    terms21: "· 我们的服务模式发生重大变化。",
    terms22:
      "· 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等;",
    terms23: "· 个人信息共享、转让或公开披露的主要对象发生变化;",
    terms24: "· 您参与个人信息处理方面的权利及其行使方式发生重大变化;",
    terms25:
      "· 我们负责处理个人信息安全的责任部⻔、联络方式及投诉渠道发生变化时;",
    terms26:
      "我们鼓励您在每次使用服务时都查阅本政策，您有责任定期检查条款的更新和更改。您可以通过“33boxes”平台的“隐私政策”中查看本政策。",
    terms27:
      "“33boxes”的所有服务均适用本政策。但如果某些服务涉及特定的个人信息保护政策时，以该特定个人信息保护政策为准。",
    terms28: "第三方网站及广告",
    terms29:
      "为满足您的多元需求，向您提供更优质的服务，网站也会包含第三方网站或广告链接。我们对第三方网站、广告的内容和材料不负担任何责任或赔偿。我们不对您与第三方网站或广告主的任何交易承担任何责任或赔偿。",
    terms30: "免责声明",
    terms31:
      "我们提供的服务⻛险由您自行承担。除我们在书面材料中另有规定外，我们的服务及其中的任何内容均按“原样”和“可用”提供，不作任何明示或暗示的保证，包括但不限于对适销性、适合特定目的、标题和不侵权的默示保证。此外，33boxes不代表或保证我们的服务准确、完整、可靠、当前或无错误。33boxes不代表或保证您订购的商品的价格估计是最低或最佳可用价格，33boxes也不提供价格匹配。虽然33boxes尽最大努力确保您使用我们的服务及其中的任何内容是安全的，但我们不能也不代表或保证我们的服务或服务器没有病毒或其他有害组件。您承担服务质量和性能的全部⻛险。",
    terms32: "赔偿",
    terms33:
      "当在您使用或滥用本网站时而产生的所有责任、索赔和费用(包括但不限于维权费、公证费、保全费、律师费、诉讼费、差旅费、通讯费等)将由您个人自行承担。在33boxes有要求的时候，您须为33boxes及其关联方进行辩护、赔偿，保证他们免于任何由于以下原因导致的责任、索赔、损失、成本和支出:",
    terms34: "· 您对本网站的使用或者与本网站有关的活动",
    terms35: "· 您对本协议的任何违犯行为",
    terms36:
      "· 您通过网站提供的任何内容被指控侵权或者以其他方式侵犯任何第三方版权、商标、商业秘密、隐私或其他知识产权或其他权利 ",
    terms37:
      "如果您将在任何辩护中与33boxes合作，则您须自费负责对可能发生的赔偿及任何事项进行独家辩护和控制。",
    terms38: "国际用途",
    terms39:
      "我们不声明本网站上的内容适合或可用于瑞士以外的地点。如果您选择从瑞士以外的地点访问本网站，您需要遵守您当地的法律。",
    terms40: "解决纠纷的法律选择和地点",
    terms41:
      "您同意，无论其法律冲突规则如何，瑞士楚格州的法律和瑞士联邦法律有权管辖这些使用条款、其主题事项、 您对本网站的使用以及您可能对我们提出的任何索赔或争议，并且《联合国国际货物销售合同公约》不适用。",
    terms42:
      "您还同意，您可能对我们提出的任何争议或索赔将由位于瑞士楚格州或联邦的法院解决，并且您同意并同意此类法院行使属人管辖权，以便对任何此类索赔或诉讼提起诉讼。",
    terms43:
      "通过同意这些使用条款，您是:(1)放弃您 可能根据包括您自己的其他司法管辖区的法律对我们提出的索赔;(2)不可撤销地同意瑞士楚格州或联邦法院对 您与我们的任何争议或索赔的专属管辖权和地点;以及(3)为了解决任何此类争议或索赔，将自己置于此类法院的属人管辖权之下。",
    terms44: "可分割性和集成性",
    terms45:
      "本合同和本网站上发布的任何补充条款、政策、规则和准则构成您与我们之间的完整协议，并取代之前的所有书面或口头协议。如果本使用条款的任何部分被认定无效或不可执行，则该部分应以符合适用法律的方式解释，以尽可能反映各方的初衷，其余部分应保持完全有效。",
    terms46: "终止",
    terms47:
      "我们保留随时修改服务或暂停或停止提供全部或部分服务的权利。您还有权随时停止使用我们的服务。对于与您无法访问或使用我们的服务相关的任何损失或损害，我们不承担责任。",
    terms48: "数据相关",
    terms49:
      "当您开始使用33boxes提供的链接、脚本、cookies和其他技术时，我们将收集和存储有关您的用户和读者的信息。我们使用这些信息进行佣金处理和数据分析等。由于系统限制，一些跟踪没有得到商家的全力支持，也无法在用户级别进行跟踪。我们保留最终解释权。",
    terms50: "货品",
    terms51:
      "33boxes禁止销售假冒服装，并遵守与贩运假冒服装有关的所有适用法律。网站上出售的品牌不以任何方式与33boxes合作或附属。然而，33boxes与寻求寻找假冒商品来源的品牌充分合作，其中包括披露提交假冒商品的发货人的联系方式。",
    terms52: "商标声明与知识产权",
    terms53:
      "33boxes在任何商业场合提供的服务中包含的标识、版面设计、排版方式、文本、图片、图形等均受著作权、商标权及其它法律保护，未经相关权利人同意，上述内容均不得在任何平台被直接或间接发布、使用、出于发布或使用目的的改写或再发行，或被用于其他任何商业目的。",
    terms54:
      "用户在33boxes上发表的全部原创内容，著作权均归用户本人所有。用户可授权第三方以任何方式使用，不需要得到33boxes的同意。",
    terms55:
      "为了促进信息的分享和传播，用户将其在33boxes上发表的全部内容，授予33boxes免费的、不可撤销的、非独家使用许可，33boxes有权将该内容用于33boxes各种形态的产品和服务上，包括但不限于网站以及发表的应用或其他互联网产品。",
    terms56:
      "第三方若出于非商业目的，将用户在33boxes上发表的内容转载在33boxes之外的地方，应当在作品显著位置注明原作者姓名(或原作者在33boxes上使用的帐号名称)，给出原始链接，注明「发表于33boxes」，并不得对作品进行修改演绎。若需要对作品进行修改，或用于商业目的，第三方应当联系用户获得单独授权，按照用户规定的方式使用该内容。",
    terms57:
      "在33boxes上传或发表的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出关于著作权的异议，33boxes有权根据实际情况删除相关的内容，且有权追究用 户的法律责任。给33boxes或任何第三方造成损失的，用户应负责包括但不限于诉讼费、保全费、执行费、审计费、公证费、律师费、维护费等费用与实际的损失等在内的全额赔偿。",
    terms58:
      "如果任何第三方侵犯了33boxes用户相关的权利，用户同意授权33boxes或其指定的代理人代表33boxes自身或用户对该第三方提出警告、投诉、诉讼、或谈判和解，并且用户同意在33boxes认为必要的情况下参与共同维权。",
    terms59:
      "33boxes有权但无义务对用户发布的内容进行审核，有权根据相关证据结及有关法律法规及和本政策指导原则对侵权信息进行处理。",
    privacy1: "欢迎来到33boxes(三三合)。",
    privacy2:
      "33boxes是一家瑞士公司，注册地点位于xxxxxxxxxxxxxxx。33boxes(三三合)网站(以下简称“网站”、“33boxes”、“我们”、“我方”、“我们的”、“三三合”)是一个电子商务平台，为客人提供购买服装、鞋履、配饰、珠宝及腕表、美妆、家居产品和二手物品购买信息和渠道。(“客人”指使用或注册账户使用本33boxes的个人)",
    privacy3:
      "您注册、登录33boxes或使用33boxes提供的服务，即表示您同意接受本政策。在您开始使用我们的产品和服务前，请您务必仔细阅读并理解本政策，确保您充分理解之后再开始使用。本政策中涉及的相关术语，我们尽量以简明扼要的方式进行表述，以便您更好地理解。",
    privacy4:
      "如对本政策内容有任何疑问、意⻅或建议，您可通过本政策文末的联系方式与我们联系。",
    privacy5: "本通知涵盖什么? ",
    privacy6:
      "本通知涵盖33boxes对您访问服务和/或访问内容时收集的个人数据的处理。我们解释我们收集哪些信息，我们为什么收集这些信息，以及您对个人数据的选择。",
    privacy7: "33boxes收集哪些信息，以及如何收集?",
    privacy8:
      "作为客户，您没有任何法律义务向33boxes提供任何信息。然而，为了正常运作，我们需要您提供某些信 息，包括代表您完成交易。登录凭据(电子邮件和用户名)使我们能够个性化和改进我们的服务。您特此同意 并承认，您确实向我们提供的任何信息都是出于本隐私声明中所述目的自愿提供的，我们可能会将此类信息保存在数据库中，该数据库将根据适用法律和法规进行注册和保存。",
    privacy9:
      "特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息; 当您的信息可以单独或结合其他信息识别到您的个人身份时，或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。",
    privacy10: "A. 您向我们提供的信息",
    privacy11:
      "我们接收和存储您在服务上输入的任何信息，或以任何其他方式向我们提供。您提供的信息类型包括但不限于您的全名、电子邮件地址、用户名、密码、家庭和账单地址、发货地址、在网站上进行的交易的订单详细信息以及付款信息(付款信息由我们的支付处理器而不是我们持有)。",
    privacy12: "B. 自动收集的信息",
    privacy13:
      "1. 每当您与网站交互或使用服务的其他功能时，我们都会接收和存储某些类型的信息。换句话说，当您使用服务时，我们意识到了它，并可能单独或通过第三方服务收集、收集和记录与此类使用相关的信息，但不会超出必要的使用目的，详情如下。",
    privacy14:
      "33boxes会自动从您的浏览器接收和记录我们服务器日志上的信息，包括您 的IP地址、某些cookie信息(有关更多信息，请参阅我们的Cookie政策)唯一标识符(例如单击ID)和您请求的⻚面。",
    privacy15:
      "2. 我们自动收集使用信息，例如服务访问者的人数和频率，类似于用户音乐偏好数据或电视收视率，这些评级表明有多少人观看了特定节目。33boxes仅以汇总形式使用此数据，即作为统计指标，而不是以识别您个人身份的方式使用。这种类型的汇总数据使我们能够确定客户使用部分服务或内容其他功能的频率，以便我们 能够使服务对尽可能多的客户有吸引力，并改进内容。 ",
    privacy16:
      "3. 我们还收集有关您用于访问我们服务的设备的信息。例如，这包括您使用的计算机或设备类型、硬件型号、操作系统和软件。",
    privacy17:
      "4. 我们在提供产品和服务的过程中，可能需要使用您设备中的系统权限来收集相关个人信息。您可以选择是否授权我们使用您的设备相关权限，即使授权后也允许您自行关闭或者再开启。",
    privacy18:
      "请您注意，您开启这些权限即代表您授权我们可以按本政策的规定收集和使用这些个人信息，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。",
    privacy19: "电子邮件通信",
    privacy20:
      "如果您的计算机支持此类程序，当您打开33boxes的电子邮件时，我们通常会收到确认。33boxes使用此确认来帮助我们决定如何使电子邮件更有趣和有帮助。",
    privacy21: "位置信息",
    privacy22:
      "当您下载或使用33boxes或其关联公司或子公司创建的应用程序，并在适用的情况下请求或同意定位服务时，我们可能会收到有关您位置的信息。我们可能会使用这些信息为您提供个性化内容和基于位置的服务，例如搜索结果。大多数移动设备允许您关闭定位服务。要了解有关您的设备向我们提供的信息的更多信息，请检查您的设备制造商或软件提供商的政策。",
    privacy23: "关于Cookies",
    privacy24:
      "为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储名为Cookies的小数据文件。Cookies通常包含标识符、站点名称以及一些号码和字符。",
    privacy25:
      "我们不会将Cookies用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以进行相应的数据清除操作，或可修改对Cookies的接受程度或拒绝我们的Cookies。您可能因为这些修改，无法使用依赖于Cookies的服务或相应功能。",
    privacy26:
      "33boxes和我们的合作伙伴、关联公司、分析或服务提供商使用Cookies、信标、标签和脚本等技术。这些技术 用于分析趋势、管理网站、跟踪用户在网站上的移动，并收集有关我们整个用户群的人口统计信息。我们可能会收到基于这些公司单独和汇总使用这些技术的报告。",
    privacy27:
      "有关我们如何在服务中使用Cookies的信息，请参阅我们的Cookie政策。",
    privacy28: "我们为什么要收集信息?",
    privacy29: "我们使用我们收集的信息来:",
    privacy30: "· 操作和改进服务并显示内容",
    privacy31: "· 允许您充分利用服务，包括使用“希望列表”",
    privacy32: "· 允许您在网站上订购产品(如适用)",
    privacy33: "· 通过第三方支付处理器处理或便利付款(如适用)",
    privacy34: "· 推荐相关产品，让您更便捷的跟踪感兴趣的产品",
    privacy35:
      "· 为您提供个性化的服务和33boxes网站的体验。这个过程构成了GDPR下的特征分析",
    privacy36: "· 与您就订单进行沟通，并提供客户服务、技术援助和收集反馈",
    privacy37: "· 与您沟通产品、销售提醒、服务和促销优惠",
    privacy38: "· 如果您有帐户，请管理您的帐户，并更新我们的记录",
    privacy39:
      "· 使第三方服务提供商和承包商能够代表我们履行技术、后勤或其他职能",
    privacy40:
      "· 开展研究，解决问题，并发现并防范错误、滥用服务、欺诈或其他犯罪活动",
    privacy41: "· 控制⻛险，遵守法律法规，遵守其他法律程序和执法要求",
    privacy42: "· 生成汇总统计报告(前提是报告没有识别客户)",
    privacy43: "我们将把您的信息存储在哪里?",
    privacy44:
      "我们仅会在达到本政策所述目的所必需的时限内保存您的个人信息，同时将结合法律有强制的留存要求期限的规定确定。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或者进行匿名化处理。",
    privacy45:
      "为了提供服务，我们从您那里收集的信息可能会转移到欧洲经济区(“EEA”)以外的目的地并存储。它也可能由在欧洲经济区以外为我们工作的工作人员或我们的第三方服务提供商之一处理和使用。此类工作人员可能参与满足您的请求、处理您的付款细节和提供支持服务。我们将采取一切合理必要的步骤，确保我们向其传输任何数据的第三方对该数据提供充分保护。",
    privacy46: "与第三方共享信息",
    privacy47:
      "· 合法原则:与合作方合作过程中涉及数据使用活动的，必须具有合法目的、符合法定的合法性基础。如果合作方使用信息不再符合合法原则，则其不应再使用您的个人信息，或再获得相应合法性寄出后再行使用。",
    privacy48:
      "· 正当与最小必要原则：数据使用必须具有正当目的，且应以达成的必要为限。",
    privacy49:
      "· 安全审慎原则:我们仅按照本通知所述共享您的信息，并要求与我们共享您信息的商业实体同意对其进行保密，我们将审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包(SDK)、应用程序接口(API)进行严格的安全监测，以保护数据安全。同时要求可能接触到您个人信息的人员履行相应的保密义务，如果未能履行这些义务，可能会被追究法律责任或被中止/终止与我们的合作关系",
    privacy50: "A. 33boxes Partners",
    privacy51:
      "1. 我们与各种合作伙伴(“33boxes Partners”、“33boxes 合作伙伴”)合作，为您提供网站上尽可能广泛的时尚选择。通过服务购买产品有两种方式:",
    privacy52:
      "· 在33boxes Partner网站购买:有时，当您选择产品时，您会直接访问 33boxes Partner 网站，并由33boxes Partner要求您提供与交易相关的更多个人详细信息。您在33boxes Partner网站上输入的信息将根据33boxes Partner的隐私政策进行收集、存储和处理。",
    privacy53:
      "· 通过33boxes的集成结账(“集成结账”)在网站上购买:对于您希望购买的某些产品，我们运营集成结账服务，允许您在网站上结账这些产品。为了处理这些交易，我们需要使用我们的安全系统与相关的 33boxes合作伙伴共享您的交易信息(如名称和发货详细信息)。您在综合结账上输入的信息将根据本通 知和您希望向其购买产品的 33boxes 合作伙伴的隐私政策进行收集、存储和处理。",
    privacy54: "请注意:",
    privacy55:
      "· 在完成任何涉及33boxes合作伙伴的交易之前，您应该仔细审查33boxes Partner隐私政策;",
    privacy56: "· 33boxes Partner全权负责使用和共享您的信息。",
    privacy57: "2. 促销",
    privacy58:
      "我们还可能与33boxes Partners共享您与某些促销和比赛相关的数据。这些将受您在参加任何此类比赛或促销时提供的额外规则和条件的约束。您应仔细审查与此类比赛和促销相关的规则和条件。",
    privacy59: "3. 汇总信息",
    privacy60:
      "我们还可能与33boxes合作伙伴共享有关客户如何集体使用内容的汇总信息。我们共享此类统计数据，以便33boxes合作伙伴了解人们使用内容的频率，以便他们也可以为您提供最佳的在线体验。33boxes从不以识别您个人身份的方式向33boxes合作伙伴披露汇总信息，除非我们需要代表您完成交易。",
    privacy61: "B. 支付处理器",
    privacy62:
      "对于通过集成结账在网站上进行的购买，您的付款信息(例如信用卡或借记卡详细信息)将由第三方支付流程提供商(如Klarna或Stripe)处理。",
    privacy63: "请注意:",
    privacy64:
      "· 在通过集成结账服务完成任何交易之前，您应该仔细审查第三方支付处理器的隐私政策;",
    privacy65: "· 第三方支付处理器对其使用和共享您的信息负全部责任。",
    privacy66: "C. 附属网络",
    privacy67:
      "33boxes与乐天(“附属网络”)等附属网络共享个人数据，目的是让33boxes作为出版商参与附属网络并与合作伙伴进行接触。当关联网络为自身允许的目的收集和使用个人数据时，关联网络在该个人数据方面担任独立和独立的控制器。附属网络将根据适用的数据保护法律和法规使用个人数据，并将单独和单独履行其作为主计⻓适用的所有义务。",
    privacy68: "D. 广告商",
    privacy69:
      "广告商和广告网络可能会使用Cookies等自动化技术来收集有关您在网站和其他网站上活动的信息。这用于针对 您可能感兴趣的在线广告。有关Cookies以及如何拒绝定向广告或基于兴趣的广告Cookies的更多信息，请阅读我们的Cookie政策。",
    privacy70: "E. 社交媒体和其他平台服务",
    privacy71:
      "第三方社交媒体服务的信息: 我们在与本网站互操作的第三方网站上收到您的帐户和个人资料的用户名和密码。例如，通过Facebook登录网站，您允许我们从您的Facebook帐户获取某些信息，例如您的姓名和电子邮件地址。这可能会让我们自动访问社交媒体网站保留的关于您的某些个人信息(例如您查看的内容、您喜欢的内容以及有关您已经显示或可 能已点击的广告的信息)。当我们收到此信息时，我们将使用这些信息进一步个性化您在33boxes网站上的体验。我们可能会与这些第三方网站共享您的数据(例如从您在我们网站或应用程序上的注册信息派生的唯一标识符)。这允许我们链接您的设备，并为您提供您使用的不同设备的无缝体验。 ",
    privacy72:
      "我们有时与Facebook等社交媒体服务合作，为合作伙伴提供自定义受众。自定义受众是从客户列表中产生的受众类型。这是一种有针对性的广告形式，允许我们的合作伙伴向Facebook上的现有受众做广告。如果33boxes创建自定义受众，我们将使用我们已经持有的关于用户的汇总信息，例如电子邮件地址。我们的用户信息在发送到Facebook之前会被散列。这个过程将信息转换为随机代码，无法逆转。",
    privacy73:
      "或者，合作伙伴可能会要求我们与Facebook合作，以创建相似的受众。Facebook将从源受众(例如我们已经与他们共享的自定义受众)中生成一个⻓相相似的受众。Facebook通过识别源受众中人们的素质，并向“看起来像”源受众的人提供有针对性的广告，从而创建了一个⻓得相似的受众。相似的受众是合作伙伴接触对我们的服务感兴趣的新用户的一种方式，因为他们与我们现有的用户相似。",
    privacy74:
      "我们还可能与谷歌等平台合作，从我们持有的电子邮件地址创建受众群，并重新定位不同广告网络的用户。",
    privacy75: "F. 分析",
    privacy76:
      "我们已实现谷歌分析人口和兴趣报告功能，并可能将您的信息共享给分析和搜索引擎提供商，以帮助我们改进和优化服务，为了保障您的数据安全，该种分析以指数分分值的方式提供，数据分析在严格去标识化安全措施前提下进行。",
    privacy77: "G. 代理",
    privacy78:
      "我们雇用其他公司和人员代表我们执行任务，并且需要仅在向您提供产品或服务所必需的范围内与他们共享您 的信息。除非我们以不同方式告诉您，否则33boxes的代理商没有任何权利使用我们与他们共享的信息，超出了必要的帮助。",
    privacy79: "H.用户个人资料和你发布的其他信息",
    privacy80:
      "如果网站上提供该服务，包括您的用户名、姓名、电子邮件地址和您输入的其他信息(“用户提交”)在内的用户配置文件信息可能会显示给其他33boxes用户，以促进服务内的用户互动，并允许您关注其他用户，并允许他们关注您。电子邮件地址用于向用户配置文件添加新的用户提交，并通过用户提交进行通信。您自愿在线披露的任何信息(在讨论板、消息和聊天区、通过产品⻚面的评论等)都公开，并可供他人查看、收集和使用。您提交给服务的任何图像、字幕、物理描述、个人兴趣或其他内容都可以通过互联网和其他媒体渠道重新分发，并可能被公众查看。 ",
    privacy81:
      "请注意，每当您自愿将信息发布到服务或任何其他公共论坛的公共区域时，公众都可以访问此类信息。",
    privacy82: "I. 遵守法律要求",
    privacy83:
      "当我们真诚地认为有必要(1)遵守法律、法规或法律要求;(2)在其他协议中执行或适用我们的使用条件; 或(3)保护33boxes、我们的员工、我们的用户或其他人的权利、财产或安全时，我们可能会向相关第三方 提供您的信息。这可能包括与其他公司和组织交换信息，以保护欺诈和降低信用⻛险。",
    privacy84: "J. 考虑进行公司交易的第三方",
    privacy85:
      "在某些情况下，我们可能会选择购买或出售资产。在这些类型的交易中，客户信息通常是转移的业务资产之一。此外，在合并、收购、重组、资产出售的情况下，或在33boxes倒闭或破产的情况下，客户信息可能是转让给第三方或由第三方收购的资产之一。如果发生此类转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意，第三方受让人可能会继续使用本通知中规定的您的信息。",
    privacy86: "K. 附属机构 ",
    privacy87:
      "我们可能会与我们的任何母公司、子公司、合资企业或其他共同控制的公司共享您的部分或全部信息",
    privacy88: "您的信息如何保持安全?",
    privacy89:
      "我们注意维护适当的保障和安全功能，以保护您向我们提供的信息的完整性和隐私性，并努力防止未经授权访问这些信息。我们加密您的信息以保护其免受未经授权的使用，并使用假名化、信息访问控制和防火墙。 ",
    privacy90:
      "您提供给我们的所有信息都托管和存储在爱尔兰的亚⻢逊网络服务云服务上，该服务也符合ISO27001标准。有关亚⻢逊为保护其服务器和您信息安全而采取的措施的更多信息，请参阅:http://aws.amazon.com/security/。如果您对我们云提供商提供的安全性不满意，您应该限制您在日志数据中包含的信息的性质和数量，或者不使用服务。任何支付交易都将使用安全套接字层(SSL)技术进行加密。",
    privacy91:
      "我们会尽全力保护您的个人信息，但请您理解，由于技术的限制以及可能存在的各种恶意手段，互联网行业并非绝对安全的环境。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码和相关个人信息排除给他人等。如果您选择使用33boxes创建一个帐户(“33boxes帐户”)，您的33boxes帐户中的信息将受到密码的保护，以保护您的隐私和安全。您需要通过适当选择和保护密码，并在您完成访问帐户后注销来限制对计算机和浏览器的访问，以确保不会未经授权访问您的帐户和信息。您有责任保护您用于访问服务的密码以及密码下的任何活动或操作。33boxes鼓励您在帐户中使用“强”密码(使用大写字母和小写字母、数字和符号组合的密码)。33boxes不能也不会对您因未遵守上述要求而导致的任何损失或损害负责。",
    privacy92:
      "通过互联网传输信息并不完全安全，我们无法保证您传输到服务的数据的安全性。未经授权的进入或使用、硬件或软件故障和其他因素可能会随时危及用户信息安全。任何数据传输都由您自行承担⻛险。",
    privacy93: "第三方网站的链接怎么办?",
    privacy94:
      "该服务包含指向33boxes Partners等其他网站的链接。33boxes不对其他网站上的隐私政策和/或做法负责。当链接到另一个网站时，您应该阅读该网站上声明的隐私政策。本通知仅适用于在服务上收集的信息。",
    privacy95: "数据保留",
    privacy96:
      "在遵守任何法律义务以延⻓您的信息的前提下，我们会将您提供给我们的数据存储在向您提供服务所必需的时间(如果您有33boxes帐户，只要您有一个33boxes帐户)，或者直到您要求我们删除它，以先到者为准。 ",
    privacy97:
      "在用户请求删除任何数据后，将开始永久删除相关数据的进程。一旦开始，这个过程将无法逆转，数据将被永久删除。任何未删除的数据应以匿名方式保存。",
    privacy98:
      "33boxes以汇总和匿名格式收集和保留有关服务使用情况的使用数据、其他元数据和统计信息。",
    privacy99: "我们处理您信息的法律依据是什么?",
    privacy100: "我们在有法律依据的地方处理您的信息。",
    privacy101: "A. 为了我们的合法利益而必需。我们的合法利益包括:",
    privacy102: "· 改进 33boxes网站和服务，为您提供个性化体验。 ",
    privacy103: "· 确保我们的营销沟通、广告和建议与您相关。 ",
    privacy104: "· 了解客户对服务的使用情况。",
    privacy105: "· 确保服务得到正确管理。",
    privacy106: "B. 履行我们与您合同所必需的。 ",
    privacy107:
      "例如，为了注册和管理您的33boxes帐户，我们收集您的电子邮件地址、密码和您希望添加到帐户中的任何其他详细信息。",
    privacy108: "C. 基于您的同意。 ",
    privacy109:
      "例如，您向我们提供营销同意书，以便向您发送您可能感兴趣的产品信息。您的同意可以随时联系我们撤回。",
    privacy110: "D. 遵守相关法律义务所必需的。",
    privacy111: "例如，向执法部⻔进行强制性披露",
    privacy112: "对于我们持有的关于你的信息，您有什么权利?",
    privacy113:
      "对于我们持有的关于您的信息，您享有某些权利，详⻅下文。其中一些权利仅适用于某些情况。",
    privacy114: "A. 访问 ",
    privacy115:
      "如果您总部位于欧盟，根据GDPR，您有合法权利要求访问我们持有的您的个人数据副本。为此，请通过gdpr@33boxes.com与我们联系，使用主题行“DSAR”，并包含您正在寻找哪些个人数据的详细信息。 ",
    privacy116: "B. 删除",
    privacy117:
      "要要求我们删除您的 33boxes 帐户，请按照33boxes 帐户的“帐户设置”部分中的说明操作。 ",
    privacy118: "在以下情形中，您可以向我们提出删除个人信息的请求:",
    privacy119: "· 处理目的已实现、无法实现或者为实现处理目的不再必要;",
    privacy120: "· 我们已停止提供产品或者服务，或者保存期限已届满;",
    privacy121: "· 您已明确撤回同意;",
    privacy122:
      "· 如您有足够的理由认为我们违反法律、行政法规或者违反约定处理您的个人信息;",
    privacy123: "· 法律、行政法规规定的其他情形。 ",
    privacy124:
      "请注意，尽管您的个人数据可能会从我们的数据库中删除，但33boxes可能会保留您提供的数据中包含的匿名信息，这些信息将继续被我们用于统计目的。",
    privacy125: "C. 更正 ",
    privacy126:
      "如果您有33boxes帐户，您可以更新“帐户”设置中的某些信息，例如您的姓名、电子邮件地址、用户名和密码。您可以更新或修改的信息可能会随着服务的变化而变化。",
    privacy127: "D. 营销选择退出",
    privacy128:
      "当您注册33boxes帐户时，您可以同意接收我们的某些营销电子邮件或其他通信。我们将使用您的信息，例如 您的电子邮件地址，偶尔向您提供您可能感兴趣的产品和服务信息。",
    privacy129:
      "如果您不再希望收到我们的营销消息，或者您想更改这些消息的类型和频率，您可以随时在注册33boxes帐户的帐户设置中更改首选项，或单击我们营销电子邮件中的取消订阅按钮。请注意，有时需要很短的时间才能刷新我们的记录。如果您选择停止接收营销信息，我们要求所有33boxes合作伙伴满足您的退出请求。如果出于任何原因，您的退出请求没有得到满足，请告诉我们，我们将尝试解决这些问题。",
    privacy130:
      "使用33boxes移动应用程序时，您可以在应用程序的设置或移动设备的设置中配置您接收推送通知和其他移 动警报的首选项。",
    privacy131:
      "另请注意，您可能无法选择不接收非促销、交易性消息或有关33boxes帐户的信息(例如服务公告或管理消息)。",
    privacy132: "您始终可以选择不披露信息，即使可能需要利用服务。",
    privacy133:
      "请注意，如果您选择不接收或阅读我们的法律通知，例如本通知，这些法律通知仍将管辖您对服务的使用，您有责任审查此类法律通知是否有更改。",
    privacy134: "儿童",
    privacy135:
      "我们不会故意推销或出售供儿童购买的产品。该服务不供13岁以下儿童使用。13岁以下儿童不得在33boxes注册或使用本服务，或通过本服务提交个人数据。我们不会故意从任何13岁以下的人那里收集个人数据。如果您有任何理由相信任何13岁以下的人向我们提供了他们的个人数据，请按照以下规定与我们联系，我们将努力从我们的数据库中删除该信息。",
    privacy136: "本通知的更改",
    privacy137:
      "33boxes保留不时更改本通知的权利。我们现在收集的信息的使用受使用此类信息时有效的通知的约束。如果我们在使用个人数据的方式方面发生重大变化，我们将通过在服务上发布公告或向您发送电子邮件来通知您。 ",
    privacy138: "否则，本通知的所有其他更改自所述“上次修订”日期起生效。",
    privacy139: "问题或担忧",
    privacy140:
      "如果您对我们服务或内容的隐私有任何疑问或担忧，请在此向我们发送详细信息。我们将尽一切努力解决您的担忧。如果您的问题或担忧没有在线回答，您可以写信给我们，地址是:",
    privacy141: "xxxxxxxxxxxx",
    privacy142:
      "本协议以中文起草。如果本协议的中文文本与任何翻译不一致，应以中文版本为准。 ",
    privacy143: "最近修订日期: 2022年8月118"
  },
  list: {
    noData: "暂无数据",
    sortBy: "排列",
    filter: "筛选",
    clearAll: "清除",
    showResult: "展示结果",
    newest: "最新",
    trending: "趋势分析",
    priceLow: "价格由低到高",
    priceHigh: "价格由高到低",
    discountHigh: "从高到低的折扣",
    brandTitle: "通过选择以下类别接收更新。"
  },
  details: {
    star: "星选",
    show: "秀款",
    newIn: "新款",
    saleIn: "折扣",
    viewMore: "查看更多详细信息",
    hide: "收起",
    size: "标准尺码",
    sizeGuide: "尺码表",
    viewAll: "查看所有<b> {number} </b>家店铺和价格",
    share: "分享",
    setAlert: "设置提醒",
    addWishlist: "添加到愿望列表",
    buy: "购买",
    tips: "寻找更好的价格？",
    tipsText: "加入以最优惠价格购买全球时尚。",
    sizeGuideTitle: "尺码指南和尺码信息",
    form: "来自",
    sizeGuideText:
      "请注意，尺码转换指南仅供参考。<br/>转换可能因品牌而异。<br/>如需更多信息或建议，请联系产品商店的客服。"
  },
  offers: {
    share: "分享",
    hide: "隐藏",
    exclusive: "独家",
    code: "编码",
    expire: "失效",
    shopNow: "即刻<br>前往",
    title: "抱歉",
    brandOrStore: "品牌 / 商店",
    content:
      '没有与"<font>{text}</font>"匹配的结果。<br/>请尝试其他品牌或商店。'
  },
  brand: {
    brands: "品牌",
    myBrands: "我的品牌",
    viewAll: "查看所有",
    tipsTitle: "挚爱品牌资讯尽在掌握。",
    tipsText: "何不创建自己的品牌库？",
    searchTipsText:
      '没有商品匹配 <br/>"<font>{search}</font>".<br/>请尝试输入其他关键字.'
  },
  remind: {
    text: "33boxes 使用 cookies 收集数据以用于市场分析。使用 33boxes 网站即表示您同意这些 cookies。需要了解更多信息，请访问我们的隐私政策。",
    button: "接受"
  }
};
