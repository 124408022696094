import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import $event, { EVENT_NAME } from "@/utils/event-emitter";
const Index = () => import('@/views/Index/index.vue')
const HomePage = () => import('@/views/HomePage/homePage.vue')
const Pre = () => import('@/views/Pre/pre.vue')
const Offers = () => import('@/views/Offers/offers.vue')
const UserCenter = () => import('@/views/UserCenter/userCenter.vue')
const ProductList = () => import('@/views/Product/List/list.vue')
const BrandsList = () => import('@/views/Product/BrandsList/brandsList.vue')
const ProductDetails = () => import('@/views/Product/Details/details.vue')
const Brands = () => import('@/views/Brands/brands.vue')
const DiscoverList = () => import('@/views/Discover/List/list.vue')
const FeaturedList = () => import('@/views/Featured/List/list.vue')
const ImageText = () => import('@/views/ImageText/imageText.vue')
const Search = () => import('@/views/Search/search.vue')
const HelpCenter = () => import('@/views/Service/HelpCenter/helpCenter.vue')
const ContactUs = () => import('@/views/Service/ContactUs/contactUs.vue')
const AboutUs = () => import('@/views/Service/AboutUs/aboutUs.vue')
const Accessibility = () => import('@/views/Service/Accessibility/accessibility.vue')
const TermsConditions = () => import('@/views/Service/TermsConditions/termsConditions.vue')
const PrivacyPolicy = () => import('@/views/Service/PrivacyPolicy/privacyPolicy.vue')
Vue.use(VueRouter)
/**
 * 报错显示路由频繁点击导致路由重复而报错，该报错对路由跳转功能没有任何影响。
 * @type {{}}
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/pre',
        name: 'Index',
        component: Index,
        redirect: { name: "Pre" },
        children:[
            {
                path: '',
                name: 'Pre',
                component: Pre
            },
            {
                path: '/home/page',
                name: 'HomePage',
                component: HomePage
            },            {
                path: '/offers',
                name: 'Offers',
                component: Offers
            },
            {
                path: '/user/center',
                name: 'UserCenter',
                component: UserCenter,
            },
            {
                path: '/product/list',
                name: 'ProductList',
                component: ProductList
            },
            {
                path: '/brands/list',
                name: 'BrandsList',
                component: BrandsList
            },
            {
                path: '/product/details',
                name: 'ProductDetails',
                component: ProductDetails
            },
            {
                path: '/discover/list',
                name: 'DiscoverList',
                component: DiscoverList
            },
            {
                path: '/featured/list',
                name: 'FeaturedList',
                component: FeaturedList
            },
            {
                path: '/image/text',
                name: 'ImageText',
                component: ImageText
            },
            {
                path: '/brands',
                name: 'Brands',
                component: Brands
            },
            {
                path: '/search',
                name: 'Search',
                component: Search
            },
            {
                path: '/help-center',
                name: 'HelpCenter',
                component: HelpCenter
            },
            {
                path: '/contact-us',
                name: 'ContactUs',
                component: ContactUs
            },
            {
                path: '/about-us',
                name: 'AboutUs',
                component: AboutUs
            },
            {
                path: '/accessibility',
                name: 'Accessibility',
                component: Accessibility
            },
            {
                path: '/terms-conditions',
                name: 'TermsConditions',
                component: TermsConditions
            },
            {
                path: '/privacy-policy',
                name: 'PrivacyPolicy',
                component: PrivacyPolicy
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    document.title = 'Boxes'

    if (to.matched.length === 0) { //匹配前往的路由不存在
        if(from.name){  //判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到首页
            next({
                name: from.name
            })
        }else{
            if(localStorage.getItem('cid')){
                next('/home/page')
            }else{
                next('/pre')
            }
        }
    } else {
        if (to.name === 'Pre' && localStorage.getItem('cid')) {
            next('/home/page')
        }
        next()
        const isLogin = Cookies.get('token') //判断是否登录
        if (!isLogin) {
            $event.$emit(EVENT_NAME.LOGIN_TOKEN);
            if (to.name === 'UserCenter') {
                next('/home/page')
            }else{
                next()
            }
        } else {
            next()
        }
    }
});
export default router
