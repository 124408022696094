<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { isPhone } from "@/utils/tools";

export default {
  name: "App",
  created() {
    //在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }

    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    // !function test(){
    //     // 捕获异常，递归次数过多调试工具会抛出异常。
    //     try { !
    //         function cir(i) {
    //           // 当打开调试工具后，抛出异常，setTimeout执行test无参数，此时i == NaN，("" + i / i).length == 3
    //           // debugger设置断点
    //           (1 !== ("" + i / i).length || 0 === i) &&
    //           function() {}.constructor("debugger")(),
    //               cir(++i);
    //         } (0)
    //     } catch(e) {
    //         setTimeout(test, 500)
    //     }
    // }()
  },
  mounted() {
    if (isPhone()) {
      this.device();
      window.onresize = () => {
        return (() => {
          this.device();
        })();
      };
    }
    /**
     * 禁止双指放大
     */
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      false
    );
    /**
     * 禁止双击放大
     * @type {number}
     */
    let lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        let now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );
    document.addEventListener("gesturestart", function (event) {
      event.preventDefault();
    });
    document.addEventListener("dblclick", function (event) {
      event.preventDefault();
    });
  },
  methods: {
    device() {
      let deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth > 540) deviceWidth = 540;
      document.documentElement.style.fontSize = deviceWidth / 12.42 + "px";
    }
  }
};
</script>
<style lang="less">
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background: #fff;
  font-family: Montserrat-SemiBold, FZLT-SemiBold;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    background: transparent;
  }

  @media screen and (max-width: 540px) {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
  }
}
</style>
