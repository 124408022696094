/**
 * 系统状态管理
 */
export default {
    namespaced: true,
    state: {
        currencyCode:'', //币种代码
        countryCode:'', //国家代码
        countryList:[], //国家数据
        offsetTop:0,
    },
    mutations: {
        /**
         * 币种代码
         * @param state
         * @param code
         * @constructor
         */
        SET_CURRENCY_CODE (state,currencyCode) {
            state.currencyCode = currencyCode;
        },
        /**
         * 国家代码
         * @param state
         * @param code
         * @constructor
         */
        SET_COUNTRY_CODE (state,countryCode) {
            state.countryCode = countryCode;
        },
        /**
         * 国家数据
         * @param state
         * @param code
         * @constructor
         */
        SET_COUNTRY_LIST (state,countryList) {
            state.countryList = countryList;
        }
        ,/**
         * 滚动条高度
         * @param state
         * @param code
         * @constructor
         */
        SET_OFFSET_TOP (state,offsetTop) {
            state.offsetTop = offsetTop;
        },
    },
}
