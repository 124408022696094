/**
 * 用户中心状态管理
 */
export default {
    namespaced: true,
    state: {
        userAccount:'', //用户账号
        selectMenu:'', //选择的菜单
        userInfo:{}, // 用户信息
        alertNew:[], //新的提醒
    },
    mutations: {
        /**
         * 用户账号
         * @param state
         * @param userName
         * @constructor
         */
        SET_USER_ACCOUNT (state,userAccount) {
            state.userAccount = userAccount;
        },
        /**
         * 选择的菜单
         * @param state
         * @param selectMenu
         * @constructor
         */
        SET_SELECT_MENU (state,selectMenu) {
            state.selectMenu = selectMenu;
        },
        /**
         * 用户信息
         * @param state
         * @param userInfo
         * @constructor
         */
        SET_USER_INFO (state,userInfo) {
            state.userInfo = userInfo;
        },
        /**
         * 新的提醒
         * @param state
         * @param alertNew
         * @constructor
         */
        SET_ALERT_NEW (state,alertNew) {
            state.alertNew.push(alertNew);
        },
        /**
         * 删除新的提醒
         * @param state
         * @param alertNew
         * @constructor
         */
        DEL_ALERT_NEW (state,alertNew) {
            if(alertNew!=''){
                if(state.alertNew.indexOf(alertNew) > -1){
                    state.alertNew.splice(state.alertNew.indexOf(alertNew),1)
                }
            }else{
                state.alertNew = [];
            }
        },
    },
}
