/**
 * 产品状态管理
 */
export default {
    namespaced: true,
    state: {
        productId:'', //产品id
        letter:'', //Letter
        menuId:'', //菜单id
        brandId:'', //品牌id
        source:'', //来源
        menuType:'', //菜单分类
        selectionId:'',//选择id
        isNew:'', //新款
        isSale:'', //折扣
        isOld:'', //古着
        label:'', //标签
        level:'', //分类级别
        isParent:0, //是否是子级
        newSaleName:'', //新款折扣古着标签名称
        secondType:{ //二级分类
            categoryId:'',
            name:'',
        },
        tertiaryType:{//三级分类
            categoryId:'',
            name:'',
        },
        levelType:{//四级分类
            categoryId:'',
            name:'',
        },
        fiveType:{//五级分类
            categoryId:'',
            name:'',
        },
        showListFilter: false, // 商品筛选是否展示
        showBrandFilter: false, // 品牌筛选是否展示
        brandDetail: {} // 品牌详细信息
    },
    mutations: {
        // 商品筛选
        SET_LIST_FILTER (state, type) {
            state.showListFilter = type
        },

        // 品牌筛选
        SET_BRAND_FILTER (state, type) {
            state.showBrandFilter = type
        },

        // 品牌详细信息
        SET_BRAND_DETAIL (state, data) {
            state.brandDetail = data
        },
        /**
         * 产品id
         * @param state
         * @param productId
         * @constructor
         */
        SET_PRODUCT_ID (state,productId) {
            state.productId = productId;
        },
        /**
         * 菜单id
         * @param state
         * @param menuId
         * @constructor
         */
        SET_MENU_ID (state,menuId) {
            state.menuId = menuId;
        },
        /**
         * 品牌id
         * @param state
         * @param brandId
         * @constructor
         */
        SET_BRAND_ID (state,brandId) {
            state.brandId = brandId;
        },
        /**
         * 来源
         * @param state
         * @param source
         * @constructor
         */
        SET_SOURCE (state,source) {
            state.source = source
        },
        /**
         * 菜单分类
         * @param state
         * @param menuType
         * @constructor
         */
        SET_MENU_TYPE (state,menuType) {
            state.menuType = menuType
        },
        /**
         * 二级分类
         * @param state
         * @param menuType
         * @constructor
         */
        SET_SECOND_TYPE (state,secondType) {
            state.secondType = secondType
        },
        /**
         * 三级分类
         * @param state
         * @param menuType
         * @constructor
         */
        SET_TERTIARY_TYPE (state,tertiaryType) {
            state.tertiaryType = tertiaryType
        },
        /**
         * 四级分类
         * @param state
         * @param menuType
         * @constructor
         */
        SET_LEVEL_TYPE (state,levelType) {
            state.levelType = levelType
        },
        /**
         * 五级分类
         * @param state
         * @param fiveType
         * @constructor
         */
        SET_FIVE_TYPE (state,fiveType) {
            state.fiveType = fiveType
        },
        /**
         * 选择id
         * @param state
         * @param selectionId
         * @constructor
         */
        SET_SELECTION_ID (state,selectionId) {
            state.selectionId = selectionId
        },
        /**
         * 新款
         * @param state
         * @param isNew
         * @constructor
         */
        SET_IS_NEW (state,isNew) {
            state.isNew = isNew
        },
        /**
         * 折扣
         * @param state
         * @param isNew
         * @constructor
         */
        SET_IS_SALE (state,isSale) {
            state.isSale = isSale
        },
        /**
         * 古着
         * @param state
         * @param isOld
         * @constructor
         */
        SET_IS_OLD (state,isOld) {
            state.isOld = isOld
        },
        /**
         * 标签
         * @param state
         * @param label
         * @constructor
         */
        SET_LABEL (state,label) {
            state.label = label
        },
        /**
         * 分类级别
         * @param state
         * @param level
         * @constructor
         */
        SET_LEVEL (state,level) {
            state.level = level
        },
        /**
         * 三级标签名称
         * @param state
         * @param level
         * @constructor
         */
        SET_NEW_SALE_NAME (state,newSaleName) {
            state.newSaleName = newSaleName
        },
        /**
         * 是否是子级
         * @param state
         * @param isParent
         * @constructor
         */
        SET_IS_PARENT (state,isParent) {
            state.isParent = isParent
        },
        /**
         * letter
         * @param state
         * @param letter
         * @constructor
         */
        SET_LETTER (state,letter) {
            state.letter = letter
        },
    },
}
