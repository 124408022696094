/**
 * 语言状态管理
 */
export default {
  namespaced: true,
  state: {
    code:'', //语言代码
  },
  mutations: {
      /**
       * 语言代码
       * @param state
       * @param code
       * @constructor
       */
      SET_CODE (state,code) {
          state.code = code;
      },
  },
}
