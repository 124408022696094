export default {
  header: {
    login: "Login",
    history: "History",
    search: "Search",
    account: "Account",
    viewAll: "View all",
    alertViewAll: "View all",
    tipsTitle: "Why not try alerts?",
    tipsText: "Never miss the good stuff you love.",
    newAlert: "View all <b> {number} </b> stores and prices",
    historyTitle: "RECENTLY VIEWED",
    historyTipsTitle: "Haven’t seen any product?",
    historyTipsText: "More than 300,000 products are waiting for you."
  },
  pre: {
    brandWomen: "Women’s Featured Brands",
    brandMen: "Men’s Featured Brands",
    brandKids: "Kids’ Featured Brands",
    brandHome: "Featured Brands of Home",
    categoryWomen: "Women’s Popular Categories",
    categoryMen: "Men’s Popular Categories",
    categoryKids: "Kids’ Popular Categories",
    categoryHome: "Popular Home Goods"
  },
  login: {
    tips1:
      "Worldwide leading online retailers get together.<br/> More choices with curated style and fashion.",
    tips2:
      "Ultimate one-stop shopping destination,<br/> all sizes and styles at your fingertips.",
    tips3: "User-friendly, easy to find what you love.",
    tips4: "Compare to get the best deal.",
    tips5: "Keep you in the know of the latest vogue and style.",
    tips6: "Everyday sale and special offers.",
    tips7: "Pay directly to and shipped by retailers.",
    account: "Email address",
    newAccount: "New email address",
    password: "Password",
    loginBtn: "Login",
    show: "Show",
    hide: "Hide",
    registerBtn: "Create New Account",
    resetEmail: "Reset email address",
    forgetPassword: "Forget your password?",
    retrievePassword: "Reset Password",
    verificationCode: "Code",
    with: "Or Login with",
    withRegister: "Or Register with",
    emailTips: "Invalid credentials.",
    newEmailTips: "Email address Invalid.",
    keyPasswordTips: "Please fill in the password.",
    keyCodeTips: "Please fill in the code.",
    passwordTips: "Password must be between 8 and 30 characters.",
    newPasswordTips:
      "New password must contain at least one letter and one number.",
    passwordInvalidTips: "Password invalid.",
    accountTips:
      "Sorry，we couldn’t find your account.Please create one and enjoy. ",
    registerText:
      "By creating an account, you consent to 33boxes’ Terms & Policies.<br>To find out more about why we collect data and how we protect your privacy.<br>please read the Privacy Policy.<br>You can opt out at any time.",
    registerSuccessTips:
      "Thank you for joining 33boxes.Please login and enjoy shopping.",
    registeredPrompt: "The email has already been registered.",
    newPassword: "New password",
    resetPassword: "Reset Password",
    retrievePasswordText:
      "Please enter your login email,<br/>and a code to reset password will be sent to your mailbox.",
    resetSuccessfulTips: "Reset successful, please login.",
    resetPasswordTips:
      "The original password cannot be the same as the new password.",
    resetAccountTips:
      "The original account cannot be the same as the new account.",
    logoutConfirm: "Are you sure you want to exit the system?",
    sendCode: "Send code",
    sendError: "Fail in send，please try again.",
    resetFailed: "Reset failed，please try again.",
    resetSuccess: "Password has been sent to your login email address."
  },
  userCenter: {
    accountInfo: "Account info",
    searchAlertsWishlist: "My Search / Alerts / Wishlist",
    preferences: "Preferences",
    orderHistory: "Order history",
    logout: "Logout",
    search: "Search",
    alerts: "Alerts",
    wishlist: "Wishlist",
    deleteAll: "Delete All",
    cancel: "Cancel",
    confirm: "Yes",
    save: "Save",
    deleteTips: " Are you sure want to delete all “My likes”?",
    wishlistTitle: "Don’t let them go!",
    wishlistText: "Easy collect, easy buy.",
    alertsDeleteTips: "Are you sure want to delete all “My alerts”?",
    alertsTitle: "Why not try alerts?",
    alertsText: "Never miss the good stuff you love.",
    country: "Country / Region",
    language: "Language",
    currency: "Currency",
    newsLetter: "Newsletter",
    promotionsNotifications: "Promotions & Notifications",
    yes: "Yes",
    no: "No",
    preferencesText:
      "You can unsubscribe at any time.<br/>To find out more, please read the Privacy Policy.",
    saveTips: "Saved successfully",
    unsubscribed: "Unsubscribed",
    selectSize: "Select sizes",
    preferPrice: "Prefered price",
    priceChange: "Any price change",
    setAlert: "Set Alert",
    emailAndPassword: "Email & Password",
    change: "Change"
  },
  menu: {
    shopNow: "Shop Now",
    viewAll: "View All",
    featuredBrands: "Featured Brands",
    brands: "Brand",
    category: "Category",
    price: "Price",
    size: "Size",
    color: "Colour",
    newSale: "New in / Sale",
    newIn: "New in / Sale / Pre-owned",
    isNew: "New in",
    isSale: "Sale",
    isOld: "Pre-Owned",
    sizeGuide: "Size guide",
    clothing: "Clothing",
    retrieval: "Retrieve all"
  },
  carousel: {
    numberContent: "smart looks<br> on Super Sale.",
    more: "More",
    moreContent: "More smart looks<br> on Super Sale.",
    login: "Login",
    signUp: "Signup",
    tipsTitle: "Not a member yet?",
    tipsText1: "Join and build your own lifestyle library.",
    tipsText2: "Join to shop global fashion with exclusive offers.",
    searchTipsTitle: "Sorry",
    searchTipsText:
      'There are no products that matched "<font>{search}</font>".<br/>Please try a different keyword.'
  },
  signup: {
    women: "Women",
    men: "Men",
    kids: "Kids",
    home: "Home",
    textTips:
      "You can unsubscribe at any time.<br>To find out more, please read the Privacy Policy.",
    btn: "Subscribe to our newsletter",
    thanks: "Thanks!",
    submitText: "You will receive an email soon."
  },
  headline: {
    superSale: "SUPER SALE",
    discover: "DISCOVER",
    brandWeekly: "BRAND WEEKLY",
    featured: "FEATURED",
    registeredMember: "SIGNUP FOR 33boxes",
    more: "More",
    recommend: "RECOMMENDATIONS",
    popularKeywords: "Popular Keywords",
    popularBrands: "Popular Brands",
    featuredProducts: "Featured Products"
  },
  footer: {
    newsletterSignup: "Newsletter signup",
    helpCentre: "Help Centre",
    contactUs: "Contact Us",
    aboutUs: "About Us",
    accessibility: "Accessibility",
    termsConditions: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    service: "Service",
    HelpCenter:
      '"33boxes is a brand new international fashion shopping and lifestyle platform. With top retailers, a user-friendly interface, comprehensive and accurate information on products and channels, and unlimited new and special offers on clothing, shoes, accessories, jewellery and watches, beauty, home products and classic vintage items, 33boxes turns online shopping into a pleasure and makes your life more colourful.',
    help1: "Easy to navigate",
    help2:
      "33boxes is a brand new international fashion shopping and lifestyle platform. With top retailers, a user-friendly interface, comprehensive and accurate information on products and channels, and unlimited new and special offers on clothing, shoes, accessories, jewellery and watches, beauty, home products and classic vintage items, 33boxes turns online shopping into a pleasure and makes your life more colourful.",
    help3: "Quick Search Tags",
    help4:
      'Once you get registered, click on the "bookmark" button in the filter on the item list page to create a "quick search tag". You can go to "My Account”, click on the "My Search Tags" and that will direct you to the filtered page, streamlining the filtering process.',
    help5: "Wishlist",
    help6:
      'Upon registration, click on the “heart-shaped" button on the item to add it to your wishlist. Go to "My Account”, click on "My Wish List" for a quicker and easier checkout.',
    help7: "Alerts",
    help8:
      'Upon registration, click the “bell-shaped" button on an item to set alerts for your favorite products, so you can keep track of price changes and stock in real time.',
    help9: "About purchase and order",
    help10:
      "As a fashion and lifestyle information platform, 33boxes offers a wide range of links to retailers, discount and promotion codes, fashion and lifestyle information, but direct purchases is not available at the current stage. Therefore, when you access a retailer's website via our site, you will receive an order confirmation from the retailer.",
    help11: "Wrong product information",
    help12:
      "We are dedicated to making your shopping experience as easy and enjoyable as possible. Should there be any false product information (though it is rare), we sincerely apologize for any inconvenience. If you experience similar issues, don’t hesitate to contact our team at <font>customerservice@33boxes.com</font>",
    help13: "Sizing-related issues",
    help14:
      '33boxes offers items from a wide range of e-commerce stores and brands. Product sizing can a big challenge as sizing standards may vary from country to country, region to region, brand to brand. Therefore, we highly recommend you to refer to the "size comparison chart" while selecting your items.',
    help15: "Precise searching",
    help16: 'You can select from product list with the help of the "filters".',
    help17: "Currency, delivery and customs",
    help18:
      "33boxes sells products from a wide range of retailers across the globe, so currency, delivery and customs issues will be subject to the policies of each merchant and your country. Please contact the e-commerce brand and the relevant authorities for further details.",
    help19: "Returns and Refunds",
    help20:
      "If you are not satisfied with your purchase and require a return or refund, please contact its corresponding retailer or brand.",
    contactUs1:
      "Your valuable feedback is always welcomed and we look forward to hearing from you.",
    contactUs2:
      "If you have any questions regarding shopping, you can visit the Help Centre or email us at <font>customerservice@33boxes.com</font>",
    contactUs3:
      "If you are a shop, brand or a designer and have an interest in partnering with us or have any questions, please feel free to email us at <font>storeservice@33boxes.com</font>",
    contactUs4:
      "If you are from the media industry and have any interview requests, please feel free to reach out to us via email at <font>media@33boxes.com</font>",
    contactUs5: "Our registered office is located at: xxxxx",
    aboutUs1:
      "33boxes is a brand new fashion, lifestyle and shopping platform from Switzerland.",
    aboutUs2:
      "The world's top retailers and e-commerce brands are all at your fingertips. You can select from a wide range of premium collections. With the most updated and useful information about various items, our commitment of  creating a user-centric e-commerce platform with premium quality is honored. By bridging the information gaps, shopping is made simple, easy and time-saving. Enjoy the pleasure of shopping at 33boxes!",
    accessibility1:
      '33boxes is committed to making your online shopping experience as seamless as possible. Your valuable feedback to help us further improve your shopping experience is always welcomed. Should you find any challenges while selecting items here or find any feature may hinder the “accessibility",  don’t hesitate to contact us with details of the issue via email at: <font>accessibility@33boxes.com</font> <br/><br/>Your valuable feedback are deeply appreciated!',
    terms1: "Welcome to 33boxes.",
    terms2:
      '33boxes is a Swiss company with its registered office in xxxxxxxxxxxxxx. 33boxes (hereinafter referred to as "the Site", "33boxes", "we", "us", "our", and " SanSanHe") is an e-commerce platform, providing customers with the opportunity to purchase clothing, footwear, accessories, jewelry and watches, beauty and home furnishings, and second-hand items. ("Guest" refers to an individual who uses or registers an account to access 33boxes)',
    terms3:
      "By registering, logging into 33boxes or using the services offered by 33boxes, you agree to be bound by these Terms. Your access to and use of the Services is conditioned on your thorough comprehension, acceptance of and compliance with these Terms. We have tried to keep the terms as simple and concise as possible so that you can better understand them. ",
    terms4:
      "If you have any questions, comments or suggestions regarding the content of this policy, you can contact us via the contact details listed at the end of this policy.",
    terms5: "Registration and login",
    terms6:
      "You shall register on and log into this Site in order to use the products and services offered by 33boxes. Registration is optional, but it does allow you to enjoy extended features (e.g. favorites, alerts, etc.).",
    terms7:
      'We reserve the right to reject or remove any user name at our discretion. When you register for a "33boxes" account, you will be asked to provide your email address, username and password and we reserve the right to reject or remove any username. We will verify your identity by email and create an account for you once we have verified that your identity is valid. You can select your password.',
    terms8:
      'You can also use a third party account (e.g. Facebook) to log in and use "What\'s worth buying", where you will be required to authorize us to access your information on the third party platform (profile picture, nickname and other information you authorize). You will still need to provide your email address for the registering and connecting your "33boxes" account. Once your account has been created and connected, you may use your third party account to log in and use the product and related services provided by 33boxes directly. We may obtain such information from third parties for the purpose of remembering your login status as a user of "33boxes". You may connect and disconnect your third party account to the "33boxes" as described above at your own discretion.',
    terms9:
      '(For users who use the "Login via Apple" feature), please be aware that we do not collect Apple ID and email information stored on your device, and we reserve the right to request other information necessary to create and login to your account (email and password) in accordance with this policy. Please refer to Apple’s Privacy Policy for other personal information related questions regarding the "Login via Apple" feature.',
    terms10:
      "By signing up for 33boxes, you agree to maintain communication with us by email and newsletters.",
    terms11: "Age of the user",
    terms12:
      "Minors under the age of 16 are not allowed to use the Service and  parents or legal guardians may not agree to these Terms and Conditions on their behalf. If we become aware that a child under 16 has provided or attempted to provide us with personal information, we reserve the right to remove the information permanently from our files without the consent of the user.",
    terms13: "By accessing the Services, you represent and warrant to us that:",
    terms14:
      "You are an individual (i.e., not a corporation) at least 16 years old; You are of legal age to form a binding contract or have your parent’s permission to do so; All registration information you submit is accurate and truthful; You are legally permitted to use and access the Services and take full responsibility for your access, selection and use of the Services; If you are under the age of 18 but at least 14 years of age, you shall use this Site under the supervision of a parent or legal guardian who agrees to be bound by these Terms and Conditions; If you are a legal guardian agreeing to these Terms and Conditions for the benefit of a child aged above 16, be advised that you shall properly perform your guardianship duties and protect the security of his or her personal information, and you are fully responsible for his or her use of this Site, including all financial charges and legal liability that he or she may incur.",
    terms15: "Non-Commercial Use",
    terms16:
      "This Site and its contents are for your personal or non-commercial use only. Without the prior written consent of this Site, you shall not modify, reproduce, publish, distribute or use the content in any other way for any public or commercial purpose, or use it on any other website, print media, film, television, radio, audio, video or digital product, or in any category that may infringe the copyright of this Site. We reserve the right to sue for any unauthorized infringement of copyright and other rights on this Site.",
    terms17: "Policy Update and Scope of Application",
    terms18:
      "This policy may be updated to provide you with better service and as 33boxes further develops its business. We may discontinue or change the content or functionality of the Site and this Policy at any time without your consent. We will not, however, reduce your rights under this Policy without your express consent. We will post updates to this page on the 33boxes platform and will notify you of any such updates by means of a notice on the website or other appropriate means before they become effective, and you should review the most current version of our personal information protection policy on the 33boxes website.",
    terms19:
      "We will also provide you with more prominent notice of material changes, including, but not limited to, private notification sent through messages, email notification or a special notice on the browser page, explaining the specific changes to our personal information protection policy.",
    terms20: "Material changes of this policy include, but are not limited to:",
    terms21: "· Significant changes to our service model.",
    terms22:
      "· Significant changes in our ownership structure, organizational structure, etc., such as changes in ownership resulting from business restructuring, bankruptcy, mergers, etc.;",
    terms23:
      "· Changes in the primary parties to whom personal information is shared, transferred or publicly disclosed;",
    terms24:
      "· Significant changes in your rights to participate in the processing of personal information and how you exercise them;",
    terms25:
      "· Changes in our department responsible for handling personal information security, contact details and channels of complaint;",
    terms26:
      "We encourage you to review this policy each time you use our service and it is your responsibility to check the Terms and Conditions regularly for updates and changes. You can view this Policy through the Privacy Policy on the 33boxes.",
    terms27:
      "This Policy applies to all services provided by 33boxes. However, if certain services are subject to a specific personal information protection policy, that specific personal information protection policy will prevail.",
    terms28: "Third Party Sites and Advertisers",
    terms29:
      "33boxes may include on the Site links to third party websites or advertisers. You agree that we are not responsible or liable for any content, advertisement or other materials on third party sites. You also agree that we are also not responsible for any transactions or dealings between you and any third party or any advertiser. You agree that 33boxes is not responsible for any claim or loss due to a third party site or any advertiser.",
    terms30: "Disclaimer of Warranties",
    terms31:
      "Your use of our Services is at your sole risk. Except as otherwise provided in our written materials, our Services and any content therein are provided “as is” and “as available” without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In addition, 33boxes does not represent or warrant that our Services are accurate, complete, reliable, current or error-free. 33boxes does not represent or warrant that the price estimates for goods you order are the lowest or the best available price, nor does 33boxes offer price matching. While 33boxes makes every effort to ensure your use of our Services and any content therein safe, we cannot and do not represent or warrant that our Services or servers are free of viruses or other harmful components. You assume the entire risk as to the quality and performance of the Services.",
    terms32: "Indemnification",
    terms33:
      "You agree to defend, indemnify, and hold harmless us and our parent and other affiliated companies, and our respective employees, contractors, officers, directors, and agents from all liabilities, claims, and expenses (including, but not limited to costs of defense, notary, security, attorney's fees, litigation fees, travel and communication expenses, etc.), that arise from your use or misuse of this Site. When required by 33boxes, you will defend, indemnify and hold harmless 33boxes and its affiliates from and against any and all liabilities, claims, losses, costs and expenses arising out of:",
    terms34: "· your use of or activities in connection with the Site",
    terms35: "· any breach of this Agreement by you",
    terms36:
      "· any alleged infringement or other violation of any third party's copyright, trademark, trade secret, privacy or other intellectual property or other right by any content you provide through the Site",
    terms37:
      "We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses at your own expense.",
    terms38: "International use",
    terms39:
      "We make no representation that content on this Site is appropriate or available for use in locations outside Switzerland. If you choose to access this Site from a location outside Switzerland, you do so on your own initiative and you are responsible for compliance with local laws.",
    terms40: "Choice of Law and Location for Resolving Disputes",
    terms41:
      "You agree that the laws of Zug, Switzerland and the laws of the Swiss Confederation govern these terms of use, its subject matter, your use of the Site, and any claim or dispute that you may have against us, without regard to its conflict of laws rules, and that the United Nations Convention on Contracts for the International Sale of Goods shall have no applicability. ",
    terms42:
      "You further agree that any disputes or claims that you may have against us will be resolved by a court located in Zug, Switzerland or Federal court, and you agree and submit to the exercise of personal jurisdiction of such courts for the purpose of litigating any such claim or action. ",
    terms43:
      "By agreeing to these Terms of Use, you are: (1) waiving any claims that you might otherwise have against us based on the laws of other jurisdictions, including your own; (2) irrevocably consenting to the exclusive jurisdiction of, and venue in, the state or federal courts in Zug over any disputes or claims you have with us; and (3) submitting yourself to the personal jurisdiction of such courts for the purpose of resolving any such disputes or claims.",
    terms44: "Severability and Integration",
    terms45:
      "This contract and any supplemental terms, policies, rules and guidelines posted on this Site constitute the entire agreement between you and us and supersede all previous written or oral agreements. If any part of these Terms of Use is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.",
    terms46: "Termination",
    terms47:
      "We reserve the right to modify our Services or to suspend or stop providing all or portions of our Services at any time. You also have the right to stop using our Services at any time. We are not liable for any loss or harm related to your inability to access or use our Services.",
    terms48: "Data related",
    terms49:
      "When you start using the links, scripts, cookies and other technologies provided by 33boxes, we will collect and store information about your users and readers. We use this information for commission processing, data analysis etc. Due to system limitations, some tracking is not fully supported by the merchants and cannot be done at the user level. We reserve the right of final interpretation.",
    terms50: "Products",
    terms51:
      "33boxes prohibits the sale of counterfeit clothing and complies with all applicable laws relating to trafficking in counterfeit clothing. The brands sold on the Site are not in any way associated or affiliated with 33boxes. However, 33boxes cooperates fully with brands seeking to trace counterfeit goods, which includes disclosing the contact information of the shipper submitting the counterfeit goods.",
    terms52: "Trademark Notice and Intellectual Property Rights",
    terms53:
      "All intellectual property rights (including but not limited to logos, layout design, typography, text, presentations, illustrations, photographs, graphics and etc. in all content included on this website and in the service provided by 33box in any commercial settings) are protected by applicable copyright, trademark rights legislation and other laws around the world. Any commercial use, including the (direct and indirect) reproduction, modification, distribution and transmission of any content is strictly prohibited without the prior written consent of the relevant right holder.",
    terms54:
      "All original content published by the user on 33boxes is the copyright of the user. The user may authorize third parties to use it in any way without the consent of 33boxes.",
    terms55:
      "To facilitate the sharing and dissemination of information, the User grants 33boxes a free, irrevocable, nonexclusive license to use all content published on 33boxes in all forms. 33boxes are entitled to use these content in various intangible products and services, including but not limited to the Site and published applications or other Internet products.",
    terms56:
      "If a third party reproduces content published by the User on 33boxes elsewhere for non-commercial purposes, it should prominently note the name of the original author (or the account name used by the original author on 33boxes), provide a link to the original work, indicate that it was “published on 33boxes”, and do not modify or interpret the work. In the event that the content needs to be modified or used for commercial purposes, any third party shall contact the user to obtain his/her authorization before using the content in the manner specified by the user.",
    terms57:
      "The user shall ensure that the content uploaded or published on 33boxes is copyrighted or legally authorized and that the content does not infringe the legal rights of any third party. If a third party objects to the copyright, 33boxes reserves the right to remove the content in question as appropriate and to hold the user legally liable. If any damage is caused to 33boxes or any third party, the user shall be liable for full compensation including, but not limited to, litigation fees, security fees, enforcement fees, audit fees, notary fees, attorneys’ fees, maintenance fees and other costs and actual damages.",
    terms58:
      "If any third party violates the rights of a 33boxes user, the user agrees to authorize 33boxes or its designated agent to file a warning, complaint, lawsuit, or negotiate a settlement on behalf of 33boxes itself or the user against such third party, and the user agrees to participate in the joint defense of rights as 33boxes deems necessary.",
    terms59:
      "33boxes has the right, but not the obligation, to review the content posted by users and to deal with infringing information in accordance with the relevant evidence and the relevant laws and regulations and the guidelines of this policy.",
    privacy1: "Welcome to 33boxes.",
    privacy2:
      '33boxes is a Swiss company with its registered office in xxxxxxxxxxxxxx. 33boxes (hereinafter referred to as "the Site", "33boxes", "we", "us", "our", and " SanSanHe") is an e-commerce platform, providing customers with the opportunity to purchase clothing, footwear, accessories, jewelry and watches, beauty and home furnishings, and second-hand items. ("Guest" refers to an individual who uses or registers an account to access 33boxes)',
    privacy3:
      "By registering, logging into 33boxes or using the services offered by 33boxes, you agree to be bound by these Terms. Your access to and use of the Services is conditioned on your thorough comprehension, acceptance of and compliance with these Terms. We have tried to keep the terms as simple and concise as possible so that you can better understand them. ",
    privacy4:
      "If you have any questions, comments or suggestions regarding the content of this policy, you can contact us via the contact details listed at the end of this policy.",
    privacy5: "What does this notice cover? ",
    privacy6:
      "This Notice covers 33boxes’s treatment of personal data that 33boxes gathers when you access the Services and/or access the Content. We explain what information we gather, why we gather it and the choices you have regarding your personal data.",
    privacy7: "What information does 33boxes collect and how?",
    privacy8:
      "As customers, you do not have any legal obligation to provide any information to 33boxes. However, we require certain information from you in order to operate properly, including completing a transaction on your behalf. Login credentials (email and username) allow us to personalize and improve our services. You hereby agree and acknowledge that any information you do provide to us is provided at your own free will, for the purposes mentioned in this Privacy Notice and that we may keep such information in a database(s) which will be registered and kept in accordance with applicable laws and regulations.",
    privacy9:
      "You are kindly reminded to note that if the information cannot identify you individually or in combination with other information, it does not belong to your personal information in the legal sense; when your information can identify you individually or in combination with other information, or when we use data that cannot be linked to any specific personal information in combination with other personal information about you, such information will be treated and protected as your personal information in accordance with this Privacy Policy during the combined use.",
    privacy10: "A. Information you provide to us",
    privacy11:
      "We receive and store any information you enter on the Services or provide to us in any other way. The types of information provided by you may include, but is not limited to, your full name, email address, username, password, home and billing address, shipping address, order details of transactions carried out on the Sites, and payment information (payment information is held by our payment processors rather than us).",
    privacy12: "B. Information collected automatically",
    privacy13:
      "1. We receive and store certain types of information whenever you interact with the Sites or use another feature of the Services. In other words, when you are using the Services, we are aware of it and may gather, collect and record the information relating to such usage, either independently or through the help of third-party services, but no more than is necessary for the purpose of the use, as detailed below. ",
    privacy14:
      "33boxes automatically receives and records information on our server logs from your browser, including your IP address, certain cookie information (please refer to our Cookie Policy for further information) unique identifiers (such as click IDs) and the page you requested.",
    privacy15:
      "2. We automatically collect usage information, such as the numbers and frequency of visitors to the Services, similar to user music preference data or to TV ratings that indicate how many people watched a particular show. 33boxes only uses this data in aggregate form, that is, as a statistical measure, and not in a manner that would identify you personally. This type of aggregate data enables us to figure out how often customers use parts of the Services or another feature of the Content so that we can make the Services appealing to as many customers as possible, and improve the Content.",
    privacy16:
      "3. We also collect information about the devices you use to access our Services. This includes, for example, the type of computer or device you use, the hardware model, operating system and software.",
    privacy17:
      "4. We may need to use the system privileges on your device to collect relevant personal information when providing our products and services. You can choose whether or not to authorize us to use the relevant privileges on your device, and even after authorization, you are allowed to turn them off or on again at your discretion.",
    privacy18:
      "Please note that by enabling these privileges, you are authorizing us to collect and use such personal information as set out in this policy, and by disabling them you are cancelling these privileges and we will no longer collect and use your personal information and will not be able to provide you with the functions associated with these permissions as described above. Your decision to disable your access will not affect the processing of personal information that was previously based on your authorization.",
    privacy19: "Email communications",
    privacy20:
      "We often receive a confirmation when you open an email from 33boxes if your computer supports this type of program. 33boxes uses this confirmation to help us decide how to make emails more interesting and helpful.",
    privacy21: "Location information",
    privacy22:
      "When you download or use apps created by 33boxes or its affiliates or subsidiaries and, where applicable, have requested or consented to location services, we may receive information about your location. We may use this information to provide you with personalized content and location-based services, such as search results. Most mobile devices allow you to turn off location services. To learn more about what information your device makes available to us, please also check the policies of your device manufacturer or software provider.",
    privacy23: "About cookies",
    privacy24:
      "A cookie is a small text file that is stored on your device or mobile device when you visit certain websites. We use cookies to ensure that the Site works properly, to make your Site visit easier and to forward content that may be of interest to you. The term “cookie” in this policy covers cookies and a range of other similar technologies, such as pixel tags, site name, some numbers and characters.",
    privacy25:
      "We will not use cookies for any purpose other than those described in this policy. Most browsers provide users with the ability to clear their browser cache data and you can do so, or you can modify your cookies settings or disable the cookies. However, please be aware that if you make such changes, your online experience with the Services may be affected and some features or areas of our Platforms and Services may be limited.",
    privacy26:
      "Technologies such as cookies, beacons, tags and scripts are used by 33boxes and our partners, affiliates, analytics or service providers. These technologies are used in analyzing trends, administering the Sites, tracking users’ movements around the Sites and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.",
    privacy27:
      "Please see our Cookie Policy for information as to how we use cookies in relation to the Services.",
    privacy28: "Why do we collect information?",
    privacy29: "We use the information we collect in order to:",
    privacy30: "· Operate and improve the Services and to display Content",
    privacy31:
      "· Allow you to take full advantage of the Services, including using “Wish Lists”",
    privacy32: "· Allow you to order products on the Sites (where applicable)",
    privacy33:
      "· Process or facilitate payments through third party payment processors (where applicable)",
    privacy34:
      "· Recommend relevant products, and make it easier for you to track products you’re interested in",
    privacy35:
      "· Offer you a personalized experience of the Services and on the 33boxes Sites. This process constitutes profiling under the GDPR",
    privacy36:
      "· Communicate with you about your orders and provide customer service, technical assistance and collect feedback",
    privacy37:
      "· Communicate with you about products, sale reminders, services and promotional offers",
    privacy38:
      "· Administer your account if you have one, and update our records",
    privacy39:
      "· Enable third-party service providers and contractors to carry out technical, logistical or other functions on our behalf",
    privacy40:
      "· Conduct research, troubleshoot problems, and detect and protect against error, abuse of the Services, fraud or other criminal activity",
    privacy41:
      "· Control risk, comply with laws and regulations, and comply with other legal process and law enforcement requirements",
    privacy42:
      "· Produce aggregated statistical reports (provided that the reports do not identify customers)",
    privacy43: "Where will we store your information?",
    privacy44:
      "We will only store your personal information for as long as is necessary to achieve the purposes described in this policy, taking into account any mandatory retention periods required by law. After the retention period has expired, we may delete your personal information or anonymize it as required by applicable law. ",
    privacy45:
      "To provide the Services, the information that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (“EEA”). It may also be processed and used by staff operating outside the EEA who work for us or by one of our third-party service providers. Such staff may be engaged in, among other things, the fulfillment of your request, the processing of your payment details and the provision of support services. We will take all steps reasonably necessary to ensure that third parties to whom we transfer any data provide sufficient protection of that data.",
    privacy46: "Sharing information with third parties",
    privacy47:
      "· Lawfulness: with regard to the use of data in the course of cooperation with a partner, it must have a legitimate purpose and comply with the legal basis of lawfulness. If the use of information by a partner no longer complies with the principle of lawfulness, the partner should not use your personal information or it shall obtain the appropriate lawfulness before using it again.",
    privacy48:
      "· Data minimization: We will process Personal Data that is adequate, relevant and limited to what is necessary to achieve the purposes for which the data are processed. ",
    privacy49:
      "· Data Security: We will only share your information as described in this Policy and require that commercial entities with whom we share your information agree to keep it confidential, and we will carefully evaluate the purposes for which our partners use the data, make a comprehensive assessment of the security capabilities of those partners, and require them to comply with legal agreements for cooperation. We will conduct strict security monitoring of the software development kits (SDKs) and application programming interfaces (APIs) used by our partners to obtain information in order to protect data security. We also require those who may have access to your personal information to comply with their confidentiality obligations and failure to comply with these obligations may result in legal action or suspension/termination of the relationship with us.",
    privacy50: "A. 33boxes Partners",
    privacy51:
      "1. We work with a variety of partners (“33boxes Partners”) in order to offer you the widest possible choice of fashion items on the Sites. There are two ways to buy products through the Services:",
    privacy52:
      "· Buying on a 33boxes Partner website: Sometimes when you select a product, you will be taken directly to the 33boxes Partner website and asked by the 33boxes Partner to provide further personal details related to the transaction. The information you enter on the 33boxes Partner website will be collected, stored, and processed in accordance with the privacy policy of the 33boxes Partner.",
    privacy53:
      "· Buying on the Sites via 33boxes’s integrated checkout (“Integrated Checkout”): For certain products you wish to buy, we operate an Integrated Checkout service, allowing you to check out these products on the Sites. In order to process these transactions, we will need to share your transaction information (such as name and shipping details) with the relevant 33boxes Partner using our secure systems. The information you enter on the Integrated Checkout will be collected, stored, and processed in accordance with this Notice and in accordance with the privacy policies of the 33boxes Partners from whom you wish to purchase the products.",
    privacy54: "Please note that: ",
    privacy55:
      "· You should carefully review the 33boxes Partner privacy policy before completing any transaction involving a 33boxes Partner; ",
    privacy56:
      "· The 33boxes Partner is solely responsible for its use and sharing of your information.",
    privacy57: "2. Promotions",
    privacy58:
      "We may also share your data with 33boxes Partners in connection with certain promotions and competitions. These will be subject to additional rules and conditions which will be made available to you at the time of entering any such competition or promotion. You should carefully review the rules and conditions relating to such competitions and promotions.",
    privacy59: "3. Aggregate information",
    privacy60:
      "We may also share aggregate information with the 33boxes Partners about how our customers, collectively, use the Content. We share this type of statistical data so that the 33boxes Partners also understand how often people use the Content, so that they, too, may provide you with an optimal online experience. 33boxes never discloses aggregate information to a 33boxes Partner in a manner that would identify you personally, unless we need to in order to complete a transaction on your behalf.",
    privacy61: "B. Payment Processors",
    privacy62:
      "For purchases made on the Sites via the Integrated Checkout, your payment information (e.g. credit or debit card details) will be processed by third-party payment process providers such as Klarna or Stripe.",
    privacy63: "Please note: ",
    privacy64:
      "· You should carefully review the third-party payment processor privacy policy before completing any transaction via the Integrated Checkout service; ",
    privacy65:
      "· The third-party payment processor is solely responsible for its use and sharing of your information.",
    privacy66: "C. Affiliate Networks",
    privacy67:
      "33boxes shares personal data with affiliate networks such as Rakuten (“Affiliate Network”) for the purpose of 33boxes participating as a publisher in the Affiliate Network and entering into engagements with Partners. Where the Affiliate Network collects and uses personal data for its own permitted purposes, the Affiliate Network acts as a separate and independent Controller in relation to that personal data. The Affiliate Network will use the personal data in accordance with applicable data protection laws and regulations and will individually and separately fulfill all obligations that apply to it as a Controller.",
    privacy68: "D. Advertisers",
    privacy69:
      "Advertisers and advertising networks may use automated technologies such as cookies to collect information about your website activity on the Sites and other sites. This is used to target advertising to you online that may be of interest to you. For more information on cookies and how to reject targeted advertising or interest-based advertising cookies, please read our Cookie Policy.",
    privacy70: "E.Social media and other platform services",
    privacy71:
      "Information to/from third-party social media services:We receive the usernames and passwords for your accounts and profiles on third-party sites with which the Sites interoperate. For example, by logging into the Sites via Facebook, you are giving us permission to obtain certain information from your Facebook account, such as your name and email address. This may give us automatic access to certain personal information retained by a social media site about you (e.g. content viewed by you, content liked by you and information about the advertisements you have been shown or may have clicked on). Where this information is received by us, we will use this information to further personalize your experience on the 33boxes Sites. We may share your data (such as unique identifiers derived from your registration information on our website or app) with these third-party sites. This allows us to link your devices and provide you with a seamless experience across different devices that you use.",
    privacy72:
      "We sometimes work with social media services, such as Facebook, to provide custom audiences to partners. A custom audience is a type of audience made from a customer list. It is a form of targeted advertising that lets our partners advertise to their existing audiences who are on Facebook. In the event that 33boxes creates a custom audience, we will use aggregated information we already hold about users such as email addresses. Our user information is hashed before it is sent to Facebook. This process turns the information into randomized code and cannot be reversed.",
    privacy73:
      "Alternatively, a partner might request we work with Facebook to create a lookalike audience. Facebook will generate a lookalike audience from a source audience, such as a custom audience, we have already shared with them. A lookalike audience is created by Facebook identifying qualities of the people in the source audience and delivering targeted advertising to an audience of people who “look like” the source audience. A lookalike audience is a way for partners to reach new users who might be interested in our Services because they are similar to our existing users.",
    privacy74:
      "We may also work with platforms, such as Google, to create audience groups from the email addresses we hold and retarget users across different advertising networks.",
    privacy75: "F. Analytics",
    privacy76:
      "We have implemented the Google Analytics Demographics and Interest Reporting feature and may share your information to analytics and search engine providers that help us to improve and optimize the Services. To safeguard your data security, such analytics are provided in the form of index scores and data analysis is carried out under strict de-identification measures.",
    privacy77: "G. Agents",
    privacy78:
      "We employ other companies and people to perform tasks on our behalf and need to share your information with them only to the extent necessary to provide products or services to you. Unless we tell you differently, 33boxes’s agents do not have any right to use the information we share with them beyond what is necessary to assist us.",
    privacy79: "H. User Profiles and Other Information You Post",
    privacy80:
      "If the service is available on the Sites, User profile information including your username, name, email address, and other information you enter (“User Submissions”) may be displayed to other 33boxes users to facilitate user interaction within the Services and allow you to follow other users, and them to follow you. Email addresses are used to add new User Submissions to user profiles and to communicate through User Submissions. Any information that you voluntarily disclose online (on discussion boards, in messages and chat areas, through reviews in product pages, etc.) becomes publicly available and can be viewed, collected, and used by others. Any images, captions, physical descriptions, personal interests, or other content that you submit to the Services may be redistributed through the Internet and other media channels and may be viewed by the general public.",
    privacy81:
      "Please be aware that whenever you voluntarily post information to public areas on the Services or any other public forums, such information can be accessed by the public.",
    privacy82: "I. Compliance with legal requests",
    privacy83:
      "We may provide your information to relevant third parties when we believe in good faith that it is necessary in order to (1) comply with the law, regulation or a legal request; (2) enforce or apply our conditions of use in other agreements; or (3) protect the rights, property, or safety of 33boxes, our employees, our users, or others. This may include exchanging information with other companies and organizations for fraud protection and credit risk reduction.",
    privacy84: "J. Third parties where a corporate transaction is contemplated",
    privacy85:
      "In some cases, we may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that are transferred. In addition, in the event of a merger, acquisition, reorganization, asset sale, or in the unlikely event that 33boxes goes out of business or enters bankruptcy, customer information may be one of the assets that are transferred to or acquired by a third party. If such transfers occur, we will require the new company or organization holding your personal information to continue to be bound by this policy, or we will require that company or organization to seek your authorized consent again and the third-party transferee may continue to use your information as set out in this Policy.",
    privacy86: "K. Affiliates ",
    privacy87:
      "We may share some or all of your information with any of our parent companies, subsidiaries, joint ventures, or other companies under common control with us.",
    privacy88: "How is your information kept secure?",
    privacy89:
      "We take care to maintain appropriate safeguards and security features to protect the integrity and privacy of the information you provide us with and to try to prevent unauthorized access to it. We encrypt your information to protect it from unauthorized use, and we use pseudonymization, information access controls, and firewalls.",
    privacy90:
      "All information you provide to us is hosted and stored on Amazon Web Services cloud-based services in Ireland which is also in compliant with ISO27001 standard. For further information on the measures taken by Amazon to protect the security of their servers and your information, please see: http://aws.amazon.com/security/. If you are not satisfied with the levels of security offered by our cloud provider, you should restrict the nature and amount of information which you include in your log data or not use the Services. Any payment transactions will be encrypted using Secure Socket Layer (SSL) technology.",
    privacy91:
      "We will do our best to protect your personal information, but please understand that due to technical limitations and the possibility of malicious means, the Internet industry is not absolutely secure. You need to be aware that the systems and communication networks you use to access our services may be subject to problems due to factors beyond our control. Therefore, we strongly recommend that you take proactive measures to protect your personal information security, including, but not limited to, using complex passwords, changing your password regularly, do not share your account password and related personal information to others, etc. If you choose to create an account with 33boxes (a “33boxes Account”), the information in your 33boxes Account is protected by a password for your privacy and security. You need to ensure that there is no unauthorized access to your account and information by selecting and protecting your password appropriately and limiting access to your computer and browser by signing off after you have finished accessing your account. You are responsible for safeguarding the password that you use to access the Services and for any activities or actions under your password. 33boxes encourages you to use “strong” passwords (passwords that use a combination of upper and lower case letters, numbers and symbols) with your account. 33boxes cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.",
    privacy92:
      "The transmission of information via the Internet is not completely secure and 33boxes cannot guarantee the security of your data transmitted to the Services. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time. Any transmission of data is at your own risk.",
    privacy93: "What about links to third party websites?",
    privacy94:
      "The Services contain links to other sites such as 33boxes Partners. 33boxes is not responsible for the privacy policies and/or practices on other sites. When linking to another site you should read the privacy policy stated on that site. This Notice only governs information collected on the Services.",
    privacy95: "Data Retention",
    privacy96:
      "Subject to any legal obligations to keep your information longer, we store the data you provide to us for as long as is necessary to provide the Services to you (and if you have a 33boxes Account, for as long as you have a 33boxes Account), or until you ask us to delete it, whichever comes first.",
    privacy97:
      "After a request from a User to delete any data, a process will begin that permanently deletes the relevant data. Once begun, this process cannot be reversed and data will be permanently deleted. Any data which is not deleted shall be kept in an anonymized manner.",
    privacy98:
      "33boxes collects and retains usage data, other metadata and statistical information about the use of the Services in an aggregated and anonymized format.",
    privacy99: "What is our legal basis for processing your information?",
    privacy100:
      "We process your information where we have a legal basis for doing so.",
    privacy101:
      "A. Necessary for our legitimate interest. Our legitimate interests include:",
    privacy102:
      "· Improving the 33boxes Sites and Services and offering you a personalized experience.",
    privacy103:
      "· Ensuring that our marketing communications, advertising and recommendations are relevant to you. ",
    privacy104: "· Understanding customer usage of the Services.",
    privacy105: "· Ensuring the Services are managed correctly.",
    privacy106: "B. Necessary for the performance of our contract with you. ",
    privacy107:
      "For example, in order to register and manage your 33boxes Account, we collect your email address, password and any other additional details you wish to add to your account.",
    privacy108: "C. Based on your consent. ",
    privacy109:
      "For example, where you provide us with marketing consents in order to send you information about products that may be of interest to you. Your consent may be withdrawn at any time by contacting us.",
    privacy110: "D. Necessary to comply with relevant legal obligations. ",
    privacy111:
      "For example, to make mandatory disclosures to law enforcement.",
    privacy112:
      "What rights do you have in relation to the information we hold about you?",
    privacy113:
      "You have certain rights in relation to the information that we hold about you, which are detailed below. Some of these rights only apply in certain circumstances.",
    privacy114: "A. Access ",
    privacy115:
      "If you are based in the EU, you have a legal right under GDPR to request access to a copy of your personal data held by us. To do this, please contact us at gdpr@33boxes.com, use the subject line “DSAR” and include the details about what personal data you are looking for.",
    privacy116: "B. Deletion",
    privacy117:
      "To ask us to delete your 33boxes Account, please following the instructions in the Account Settings section of your 33boxes Account.",
    privacy118:
      "You may make a request to us to delete your personal information in the following circumstances:",
    privacy119:
      "· the purpose of the processing has been fulfilled, cannot be fulfilled or is no longer necessary to fulfill the purpose of the processing;",
    privacy120:
      "· we have ceased to provide the product or service, or the retention period has expired;",
    privacy121: "· you have expressly withdrawn your consent;",
    privacy122:
      "· If you have sufficient reasons to believe that we process your personal information in violation of laws, administrative regulations or agreements;",
    privacy123:
      "· Other situations stipulated by laws and administrative regulations.",
    privacy124:
      "Please note that although your personal data may be removed from our databases, 33boxes may retain the anonymous information contained in the data you provided, and such information will continue to be used by us for statistical purposes.",
    privacy125: "C. Corrections ",
    privacy126:
      "If you have a 33boxes Account, you can update certain information in your ‘Account’ settings, such as your name, email address, username, and password. The information that you can update or amend may change as the Services change.",
    privacy127: "D. Marketing opt-out",
    privacy128:
      "When you register for a 33boxes Account, you can give your consent to receive certain marketing emails or other communications from us. We will use your information such as your email address to occasionally provide you with information about products and services which may be of interest to you.",
    privacy129:
      "If you no longer wish to receive marketing messages from us, or if you want to change the type and frequency of these messages, you can change your preferences at any time in the Account Settings of your registered 33boxes Account, or by clicking on the unsubscribe button in our marketing emails. Please note that it sometimes takes a short amount of time to refresh our records for these purposes. We require all 33boxes Partners to honor your opt-out requests if you elect to stop receiving marketing messages. If, for whatever reason, your opt-out request is not honored, please let us know and we will attempt to resolve the issues.",
    privacy130:
      "When using the 33boxes mobile application, your preferences for receiving push notifications and other mobile alerts can be configured in the settings of the app or the settings of your mobile device.",
    privacy131:
      "Please also be advised that you may not be able to opt-out of receiving non-promotional, transactional messages, or information about your 33boxes Account (e.g. service announcements or administrative messages).",
    privacy132:
      "You can always opt not to disclose information, even though it may be needed to take advantage of the Services.",
    privacy133:
      "Please note that if you choose not to receive or read legal notices from us, such as this Notice, those legal notices will still govern your use of the Services, and you are responsible for reviewing such legal notices for changes.",
    privacy134: "Children",
    privacy135:
      "We do not knowingly market or sell products for purchase by children. The Services are not designed for use by children under 13 years old. Children under 13 are not allowed to register with 33boxes or use the Services, or submit personal data through the Services. We do not knowingly collect personal data from anyone under the age of 13. If you have any reason to believe that anyone under the age of 13 has provided their personal data to us, please contact us as specified below, and we will work to delete that information from our databases.",
    privacy136: "Changes to this Notice",
    privacy137:
      "33boxes reserves the right to change this Notice from time to time. Use of information we collect now is subject to the Notice in effect at the time such information is used. If we make substantial changes in the way we use personal data, we will notify you by posting an announcement on the Services or sending you an email. ",
    privacy138:
      "Otherwise, all other changes to this Notice are effective as of the stated “Last Revised” date.",
    privacy139: "Questions or concerns",
    privacy140:
      "If you have any questions or concerns regarding privacy on our Services or the Content, please send us a detailed message here. We will make every effort to resolve your concerns. If your question or concern is not answered online, you may write to us at ",
    privacy141: "xxxxxxxxxxxx",
    privacy142:
      "This Agreement has been drawn up in the Mandarin language. In case of discrepancies between the Mandarin text version of this Agreement and any translation, the Mandarin version shall prevail.",
    privacy143: "Last revised date: 18 August 2022"
  },
  list: {
    noData: "No data",
    sortBy: "Sort by",
    filter: "Filter",
    clearAll: "Clear all",
    showResult: "Show result",
    newest: "Newest",
    trending: "Trending",
    priceLow: "Price low to high",
    priceHigh: "Price high to low",
    discountHigh: "Discount high to low",
    brandTitle: "Receive updates by selecting the categories below."
  },
  details: {
    star: "Featured",
    show: "Runway",
    newIn: "New in",
    saleIn: "Sale",
    viewMore: "View details",
    hide: "Hide details",
    size: "Size Standard",
    sizeGuide: "Size guide",
    viewAll: "View all <b> {number} </b> stores and prices",
    share: "Share",
    setAlert: "Set alert",
    addWishlist: "Add to wishlist",
    buy: "BUY",
    tips: "Looking for better prices?",
    tipsText: "Join to shop global fashion with the best price.",
    sizeGuideTitle: "Size guide & Fit information",
    form: "form",
    sizeGuideText:
      "Please note the size conversion guide is for your reference only.<br/>Conversions may vary from brand to brand.<br/>For further information or advice, please contact customer service of the store carrying the product."
  },
  offers: {
    share: "Share",
    hide: "Hide",
    exclusive: "Exclusive",
    code: "Code",
    expire: "Expire",
    shopNow: "Shop<br/>Now",
    title: "Sorry",
    brandOrStore: "Brand / Store",
    content:
      'There are no results that matched "<font>{text}</font>". <br/>Please try another brand or store.'
  },
  brand: {
    brands: "Brands",
    myBrands: "My brands",
    viewAll: "View all",
    tipsTitle: "Never miss any news from your loved brand.",
    tipsText: "Why not make your own brand book?",
    searchTipsText:
      'There are no products that matched <br/>"<font>{search}</font>".<br/>Please try a different keyword.'
  },
  remind: {
    text: "33boxes uses cookies to collect data for functionality, analytics and marketing purposes. By using the Lyst website, you agree to these cookies. To find out more and learn how to opt out, please visit our Privacy Policy .",
    button: "Accept"
  }
};
