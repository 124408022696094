const EVENT_NAME = {
  /**
   * 切换登录提示事件名
   */
  LOGIN_SWITCH_TIPS: "LOGIN_SWITCH_TIPS",
  /**
   * 登录操作提示事件名
   */
  LOGIN_POINT_OUT: "LOGIN_POINT_OUT",
  /**
   * 登录操作token
   */
  LOGIN_TOKEN: "LOGIN_TOKEN",
  /**
   * 根据分类查询首页各项数据
   */
  HOME_PAGE_CLASSIFY: "HOME_PAGE_CLASSIFY",
  /**
   * header提示状态
   */
  HEADER_TIPS_STATUS: "HEADER_TIPS_STATUS",

  /**
   * 查询商品列表事件名
   */
  PRODUCT_LIST_QUERY: "PRODUCT_LIST_QUERY",
  /**
   * 查询商品品牌列表事件名
   */
  PRODUCT_BRANDS_LIST_QUERY: "PRODUCT_BRANDS_LIST_QUERY",
  /**
   * 查询商品菜单事件名
   */
  PRODUCT_MENU_QUERY: "PRODUCT_MENU_QUERY",
  /**
   * 查询商品品牌菜单事件名
   */
  PRODUCT_BRANDS_MENU_QUERY: "PRODUCT_BRANDS_MENU_QUERY",
  /**
   * 查询品牌列表
   */
  PRODUCT_BRANDS_QUERY: "PRODUCT_BRANDS_QUERY",
  /**
   * 商品属性查询
   */
  COMMODITY_ATTRIBUTE_QUERY: "COMMODITY_ATTRIBUTE_QUERY",
  /**
   * 底部banner查询
   */
  FOOTER_BANNER_QUERY: "FOOTER_BANNER_QUERY",
  /**
   * carousel宽
   */
  CAROUSEL_OFFSET_WIDTH: "CAROUSEL_OFFSET_WIDTH",
  /**
   * 更新头部菜单选中分类
   */
  HEADER_MENU_TYPE: "HEADER_MENU_TYPE",
  /**
   * 更新商品详情
   */
  PRODUCT_GROUP_DETAILS: "PRODUCT_GROUP_DETAILS",
  /**
   * 查询浏览记录
   */
  HISTORY_QUERY: "HISTORY_QUERY",
  /**
   * 打开登录注册弹框
   */
  OPEN_LOGON: "OPEN_LOGON",
  /**
   * 关闭登录注册弹框
   */
  OPEN_LOGON_CLOSE: "OPEN_LOGON_CLOSE",
  /**
   * 更新币种
   */
  UPDATE_CURRENCY: "UPDATE_CURRENCY",
  /**
   * 点击蒙版消除弹框
   */
  MASK_CLICK_CLOSE: "MASK_CLICK_CLOSE",
  /**
   * 点击按钮弹出登录/注册
   */
  LINKS_LOGIN_REGISTER: "LINKS_LOGIN_REGISTER"
};
export default EVENT_NAME;
