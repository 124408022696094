/**
 * 搜索状态管理
 */
export default {
    namespaced: true,
    state: {
        searchEmptyList:'',
        searchRecommendList:'',
    },
    mutations: {
        /**
         * @param state
         * @param searchEmptyList
         * @constructor
         */
        SET_SEARCH_EMPTY_LIST (state,searchEmptyList) {
            state.searchEmptyList = searchEmptyList;
        },
        /**
         * @param state
         * @param searchRecommendList
         * @constructor
         */
        SET_SEARCH_RECOMMEND_LIST (state,searchRecommendList) {
            state.searchRecommendList = searchRecommendList;
        },
    },
}
