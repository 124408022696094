import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import messages from '@/language/message'

let locale = localStorage.getItem('language')
if(!locale){
    locale = 'EN'
}

const i18n = new VueI18n({
    locale,
    messages
})
export default i18n
