/**
 *  验证邮箱
 * @param email
 * @returns {boolean}
 */
export const validateEmail = (email) => {
  let emailRe = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  return emailRe.test(email);
};

export const validatePassword = (password) => {
  // let passwordRe = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,30})$/
  // return passwordRe.test(password);
  if (password.length > 5 && password.length <= 30) {
    return true;
  } else {
    return false;
  }
};

/**
 * 回到顶部
 */
export const callBackTop = () => {
  //设置一个定时器
  let timer = setInterval(function () {
    //获取滚动条的滚动高度
    var osTop = document.documentElement.scrollTop || document.body.scrollTop;
    //用于设置速度差，产生缓动的效果
    var speed = Math.floor(-osTop / 6);
    document.documentElement.scrollTop = document.body.scrollTop =
      osTop + speed;
    if (osTop == 0) {
      clearInterval(timer);
    }
  }, 20);
};

/**
 * 转译字符 金额转换
 * @param string
 */
export const onCharacterPrice = (string) => {
  return string.replaceAll("￥", "¥");
};

/**
 * 正则切割url地址
 * @param directory
 */
export const onGetUrlKey = (directory) => {
  return (
    decodeURIComponent(
      (new RegExp("/" + directory + ".*/([^/]+)/?$").exec(location.href) || [
        ""
      ])[1].replace(/\+/g, "%20")
    ) || null
  );
};

/**
 *  随机数
 * @returns {number}
 */
export const onRandomPlatform = () => {
  let random = _s1() + _s1() + _s1() + _s1();
  return random;
};

/**
 * 生成uuid
 * @returns {string}
 * @private
 */
let _s1 = () => {
  return (((1 + Math.random()) * 0x1000) | 0).toString(16).substring(1);
};

// 判断是否为手机
export const isPhone = () => {
  return !!navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
};
