import Vue from 'vue'
import Vuex from 'vuex'
import userCenter from './userCenter'
import product from './product'
import language from './language'
import system from './system'
import search from './search'

Vue.use(Vuex)


export default new Vuex.Store({
    modules:{
        userCenter,
        product,
        language,
        system,
        search
    }
})
